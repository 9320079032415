// import FitaLogo from "../../images/FitaLogo.svg";
// import HeaderData from "../../content/header.json";
// import { NavLink, Link, useNavigate } from "react-router-dom";
// import AccountMenu from "./profile";
// import { Badge, IconButton } from "@mui/material";
// import NotificationsIcon from "../../images/notificationsIcon.svg";
// import { useEffect, useState } from "react";
// import {
//   getPushContentByStudentUid,
//   pushNotificationRead,
// } from "../../function/Student/pushNotification/PushContent";

// export const Iconbadge = (props: any) => {
//   if (window.location.pathname === props.props.routeLink) {
//     return (
//       <img
//         className="w-[30px] h-[20px] max-mobile:w-[25px] max-mobile:h-[15px] mr-2"
//         src={props.props.colorIcon}
//         alt="icon"
//       />
//     );
//   }
//   return (
//     <img
//       className="w-[30px] h-[20px] max-mobile:w-[25px] max-mobile:h-[15px]  mr-2"
//       src={props.props.icon}
//     />
//   );
// };

// export const JobIcon = (props: any) => {
//   if (
//     window.location.pathname == "/latestjob" ||
//     window.location.pathname == "/appliedjob"
//   ) {
//     return (
//       <img
//         className="w-[30px] h-[20px]"
//         src={props.props.colorIcon}
//         alt="icon"
//       />
//     );
//   }
//   return <img className="w-[30px]  h-[20px]" src={props.props.icon} />;
// };

// let jobs_list = ["/latestjob", "/appliedjob"];
// function Header() {
//   const uuid: any = localStorage.getItem("uid");
//   const navigate = useNavigate();
//   if (uuid === null) {
//     navigate("/");
//   }

//   async function signout() {
//     window.localStorage.clear();
//     localStorage.removeItem("uid");
//     navigate("");
//   }
//   const handleLink = (props: any) => {
//     navigate(props);
//   };
//   const [count, setCount] = useState(0);
//   const [load, setload] = useState(false);
//   let pushconent: any;
//   const data = async () => {
//     let pushconent: any = await getPushContentByStudentUid(uuid);

//     if (pushconent) {
//       pushconent = pushconent.filter((el: any) => {
//         return el.read != true;
//       });
//     }

//     pushconent ? setCount(pushconent?.length) : setCount(0);

//     setload(true);
//   };

//   const readNotification = async () => {
//     const pushcontent = await pushNotificationRead(uuid);

//     if (pushcontent.status == "success") {
//       setCount(0);
//     }
//   };

//   useEffect(() => {
//     data();
//   }, []);
//   return (
//     <div className="sticky top-0 z-10">
//       <header className="bg-[#fff] sticky w-full  shadow-3xl top-0 left-0 z-10">
//         <div className="sticky top-0 flex p-3 text-center align-middle bg-[#FCFCFC] w-full max-laptop:p-1 max-mobile:justify-between">
//           <div className="logo items-center flex pl-1 ">
//             <Link to="/home">
//               <img
//                 className="w-[200px] h-[60px]  max-laptop:w-[100px]  max-laptop:h-[50px]"
//                 src={FitaLogo}
//                 alt="logoIcon"
//               ></img>
//             </Link>
//           </div>
//           <div className=" w-full flex justify-end  max-mobile:hidden ">
//             <ul className="flex items-center text-center ">
//               {HeaderData?.map((item, index) => {
//                 return (
//                   <li
//                     key={index}
//                     className="text-[20px] font-normal  max-laptop:text-[14px] max-tablet:text-[12px] max-mobile:text-[12px]"
//                   >
//                     <div className="flex align-middle items-center mx-3">
//                       {item?.option === undefined ? (
//                         <NavLink
//                           to={item?.routeLink}
//                           className={({ isActive }) =>
//                             isActive
//                               ? "text-[#036AD1]  font-bold flex items-center"
//                               : " font-normal  text-[#707070]  flex items-center"
//                           }
//                         >
//                           <Iconbadge props={item} />
//                           <p className=" max-desktop:text-[20px] max-laptop:text-[16px] max-tablet:text-[14px] max-mobile:text-[12px]">
//                             {" "}
//                             {item.navLink}{" "}
//                           </p>
//                         </NavLink>
//                       ) : (
//                         <div className="flex items-center max-tablet:hidden">
//                           <JobIcon props={item} />
//                           {jobs_list.includes(window.location.pathname) ? (
//                             <select
//                               className="bg-[#FCFCFC]   text-[#036AD1]  outline-none"
//                               onChange={(e) => handleLink(e.target.value)}
//                             >
//                               <option disabled>Jobs</option>
//                               {window.location.pathname == "/latestjob" ? (
//                                 <option
//                                   selected
//                                   className=" text-[#036AD1]"
//                                   value={item.routeLink1}
//                                 >
//                                   Latest Jobs
//                                 </option>
//                               ) : (
//                                 <option value={item.routeLink1}>
//                                   Latest Jobs
//                                 </option>
//                               )}
//                               {window.location.pathname == "/appliedjob" ? (
//                                 <option
//                                   selected
//                                   className=" text-[#036AD1]"
//                                   value={item.routeLink}
//                                 >
//                                   Applied Jobs
//                                 </option>
//                               ) : (
//                                 <option value={item.routeLink}>
//                                   Applied Jobs
//                                 </option>
//                               )}
//                             </select>
//                           ) : (
//                             <select
//                               className="bg-[#FCFCFC]  text-[#707070]  outline-none"
//                               onChange={(e) => handleLink(e.target.value)}
//                             >
//                               <option disabled selected>
//                                 Jobs
//                               </option>
//                               {window.location.pathname == "/latestjob" ? (
//                                 <option
//                                   selected
//                                   className=" text-[#036AD1]"
//                                   value={item.routeLink1}
//                                 >
//                                   Latest Jobs
//                                 </option>
//                               ) : (
//                                 <option value={item.routeLink1}>
//                                   Latest Jobs
//                                 </option>
//                               )}
//                               {window.location.pathname == "/appliedjob" ? (
//                                 <option
//                                   selected
//                                   className=" text-[#036AD1]"
//                                   value={item.routeLink}
//                                 >
//                                   Applied Jobs
//                                 </option>
//                               ) : (
//                                 <option value={item.routeLink}>
//                                   Applied Jobs
//                                 </option>
//                               )}
//                             </select>
//                           )}
//                         </div>
//                       )}
//                     </div>
//                   </li>
//                 );
//               })}
//             </ul>
//           </div>
//           <div className=" flex">
//             <div className="py-4 px-2  ">
//               <IconButton>
//                 <Badge
//                   badgeContent={count}
//                   color="secondary"
//                   style={{ fontSize: "55" }}
//                 >
//                   <NavLink
//                     to="/notificationpage"
//                     state={"Instant Push Content"}
//                   >
//                     <img
//                       className="w-9 px-1  max-laptop:w-9"
//                       src={NotificationsIcon}
//                       alt="icon"
//                       onClick={() => readNotification()}
//                     />
//                   </NavLink>
//                 </Badge>
//               </IconButton>
//             </div>
//             <div className=" flex items-center mx-4 -mt-1">
//               <AccountMenu />
//             </div>
//           </div>
//         </div>
//       </header>
//     </div>
//   );
// }

// export default Header;

import React, { useEffect, useState, useRef } from "react";
import { NavLink, Link, useNavigate } from "react-router-dom";
import { Badge, IconButton } from "@mui/material";
import FitaLogo from "../../images/FitaLogo.svg";
import NotificationsIcon from "../../images/notificationsIcon.svg";
import AccountMenu from "./profile";
import HeaderData from "../../content/header.json";
import {
  getPushContentByStudentUid,
  pushNotificationRead,
} from "../../function/Student/pushNotification/PushContent";
import { Menu as MenuIcon, X } from "lucide-react";
import ProfileData from "../../content/profileData.json";
import Box from "@mui/material/Box";

export const Iconbadge = ({ props }: any) => (
  <img
    className="w-[30px] h-[20px] max-mobile:w-[25px] max-mobile:h-[15px] mr-2"
    src={
      window.location.pathname === props.routeLink
        ? props.colorIcon
        : props.icon
    }
    alt="icon"
  />
);

export const JobIcon = ({ props }: any) => (
  <img
    className="w-[30px] h-[20px]"
    src={
      ["/latestjob", "/appliedjob"].includes(window.location.pathname)
        ? props.colorIcon
        : props.icon
    }
    alt="icon"
  />
);

function Header() {
  const uuid = localStorage.getItem("uid") as string | null;
  const [count, setCount] = useState(0);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [jobMenuAnchorEl, setJobMenuAnchorEl] = useState<null | HTMLElement>(
    null
  );

  const fetchData = async () => {
    if (!uuid) return;

    try {
      const pushContent = await getPushContentByStudentUid(uuid);

      if (Array.isArray(pushContent)) {
        const unreadCount = pushContent.filter((el: any) => !el.read).length;
        setCount(unreadCount);
      } else {
        setCount(0);
      }
    } catch (error) {
      console.error("Error fetching push content:", error);
      setCount(0);
    }
  };

  const readNotification = async () => {
    if (!uuid) return;

    try {
      const response = await pushNotificationRead(uuid);
      if (response.status === "success") setCount(0);
    } catch (error) {
      console.error("Error marking notifications as read:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <header className="bg-white shadow-md sticky top-0 z-10">
      <div className="flex justify-between items-center px-6 py-3 max-sm:flex-col max-sm:gap-3">
        {/* Logo Section */}
        <Link to="/home">
          <img
            className="w-[200px] h-[60px] max-lg:w-[160px] max-lg:h-[50px] max-md:w-[120px] max-md:h-[40px]"
            src={FitaLogo}
            alt="FITA Logo"
          />
        </Link>

        {/* Navigation + Icons Section */}
        <div className="flex items-center gap-6 max-lg:gap-4 max-md:gap-2 max-sm:flex-wrap">
          <nav className="flex items-center gap-5 max-lg:gap-3 max-md:gap-2">
            {HeaderData.map((item, index) =>
              item.navLink === "Jobs" ? (
                <div key={index} className="relative group">
                  <NavLink
                    to={item.routeLink}
                    className={({ isActive }) =>
                      `flex items-center gap-1 text-[18px] max-lg:text-[16px] max-md:text-[14px] max-sm:text-[12px] ${
                        isActive ? "text-[#036AD1] font-bold" : "text-[#707070]"
                      }`
                    }
                  >
                    <Iconbadge props={item} />
                    {item.navLink}
                  </NavLink>
                  <div className="absolute top-full left-0 hidden group-hover:block bg-white shadow-md w-40">
                    <NavLink
                      to="/applied-job"
                      className={({ isActive }) =>`block px-4 py-2 text-sm hover:bg-gray-100 ${
                        isActive ? "text-[#036AD1] font-bold" : "text-[#707070]"
                      }`}
                    >
                      Applied Jobs
                    </NavLink>
                    <NavLink
                      to="/latestjob"
                      className={({ isActive }) =>`block px-4 py-2 text-sm hover:bg-gray-100 ${
                        isActive ? "text-[#036AD1] font-bold" : "text-[#707070]"
                      }`}
                    >
                      Latest Jobs
                    </NavLink>
                  </div>
                </div>
              ) : (
                <NavLink
                  key={index}
                  to={item.routeLink}
                  className={({ isActive }) =>
                    `flex items-center gap-1 text-[18px] max-lg:text-[16px] max-md:text-[14px] max-sm:text-[12px] ${
                      isActive ? "text-[#036AD1] font-bold" : "text-[#707070]"
                    }`
                  }
                >
                  <Iconbadge props={item} />
                  {item.navLink}
                </NavLink>
              )
            )}
          </nav>

          {/* Notification & Profile Section */}
          <div className="flex items-center gap-5 max-lg:gap-3 max-md:gap-2">
            <IconButton onClick={readNotification}>
              <Badge badgeContent={count} color="secondary">
                <NavLink to="/notificationpage">
                  <img
                    className="w-7 max-lg:w-6 max-md:w-5"
                    src={NotificationsIcon}
                    alt="Notifications"
                  />
                </NavLink>
              </Badge>
            </IconButton>

            <AccountMenu />
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;

function Navbar({ count, readNotification }: any) {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const userMenuRef = useRef<HTMLDivElement>(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isJobsOpen, setIsJobsOpen] = useState(false);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  // Define jobs_list
  const jobs_list = ["/latestjob", "/appliedjob"];
  const isJobsActive = jobs_list.includes(window.location.pathname);

  // Handle navigation for job selection
  const handleLink = (path: any) => {
    window.location.href = path;
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  async function signout() {
    window.localStorage.clear();
    localStorage.removeItem("uid");
    navigate("/");
  }

  // Close dropdown when clicking outside
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        userMenuRef.current &&
        !userMenuRef.current.contains(event.target as Node)
      ) {
        setIsUserMenuOpen(false);
        // setIsNavOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <nav className="bg-white border-gray-200 dark:bg-gray-900">
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
        <a href="/home" className="flex items-center space-x-3">
          {/* <img src={FitaLogo} className="h-8" alt="Flowbite Logo" /> */}
          <span className="self-center text-2xl font-semibold dark:text-white">
            FITA ACADEMY
          </span>
        </a>
        <div
          className="flex items-center space-x-3 md:space-x-0"
          ref={userMenuRef}
        >
          <div className="">
            <IconButton>
              <Badge
                badgeContent={count}
                color="secondary"
                style={{ fontSize: "55" }}
              >
                <NavLink to="/notificationpage" state={"Instant Push Content"}>
                  <img
                    className="w-9 px-1  max-laptop:w-9"
                    src={NotificationsIcon}
                    alt="icon"
                    onClick={() => readNotification()}
                  />
                </NavLink>
              </Badge>
            </IconButton>
          </div>
          <button
            type="button"
            className="flex text-sm bg-gray-800 rounded-full focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
            onClick={() => {
              setIsUserMenuOpen(!isUserMenuOpen);
              setIsNavOpen(false);
            }}
          >
            <img
              className="w-8 h-8 rounded-full"
              src="https://static.vecteezy.com/system/resources/thumbnails/005/544/718/small/profile-icon-design-free-vector.jpg"
              alt="user photo"
            />
          </button>
          {isUserMenuOpen && (
            <div className="absolute right-0 mt-2 w-48 bg-white divide-y divide-gray-100 rounded-lg shadow-sm dark:bg-gray-700 dark:divide-gray-600">
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                  position: "relative",
                }}
              >
                {isUserMenuOpen && (
                  <div className="absolute right-0 top-full mt-2 w-48 bg-white divide-y divide-gray-100 rounded-lg shadow-sm dark:bg-gray-700 dark:divide-gray-600 z-10">
                    <ul className="py-2">
                      {ProfileData.map((data) => (
                        <li key={data.routeLink}>
                          {data.routeLink !== "logout" ? (
                            <NavLink
                              to={`${data.path}`}
                              state={{ myState: `${data.routeLink}` }}
                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
                              onClick={() => setIsUserMenuOpen(false)}
                            >
                              {data.value}
                            </NavLink>
                          ) : (
                            <a
                              href="#"
                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
                              onClick={() => {
                                setIsUserMenuOpen(false);
                                signout();
                              }}
                            >
                              {data.value}
                            </a>
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </Box>
            </div>
          )}
          <button
            onClick={() => {
              setIsNavOpen(!isNavOpen);
              setIsUserMenuOpen(false);
            }}
            className="inline-flex items-center p-2 w-10 h-10 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
          >
            {isNavOpen ? (
              <X className="w-5 h-5" />
            ) : (
              <MenuIcon className="w-5 h-5" />
            )}
          </button>
        </div>
        <div
          className={`${
            isNavOpen ? "block" : "hidden"
          } w-full md:block md:w-auto`}
        >
          <nav className="bg-white border-gray-200 dark:bg-gray-900">
            <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
              <div className="w-full">
                <ul className="flex flex-col md:flex-row md:inline-flex md:flex-wrap items-center">
                  {HeaderData?.map((item, index) => {
                    return (
                      <li
                        key={index}
                        className="w-full md:w-auto py-3 md:py-0 text-[20px] font-normal max-laptop:text-[14px] max-tablet:text-[12px] max-mobile:text-[12px]"
                      >
                        {item?.option === undefined ? (
                          <div className="flex align-middle items-center justify-center md:justify-start mx-1 md:mx-3">
                            <NavLink
                              to={item?.routeLink}
                              className={({ isActive }) =>
                                isActive
                                  ? "text-[#036AD1] font-bold flex items-center"
                                  : "font-normal text-[#707070] flex items-center"
                              }
                            >
                              <Iconbadge props={item} />
                              <p className="max-desktop:text-[20px] max-laptop:text-[16px] max-tablet:text-[14px] max-mobile:text-[12px] ml-2">
                                {item.navLink}
                              </p>
                            </NavLink>
                          </div>
                        ) : (
                          <div className="relative">
                            <div
                              className={`flex align-middle items-center justify-center md:justify-start mx-1 md:mx-3 cursor-pointer ${
                                isJobsActive
                                  ? "text-[#036AD1] font-bold"
                                  : "font-normal text-[#707070]"
                              }`}
                              onClick={() => setIsJobsOpen(!isJobsOpen)}
                            >
                              <JobIcon props={item} />
                              <p className="max-desktop:text-[20px] max-laptop:text-[16px] max-tablet:text-[14px] max-mobile:text-[12px] ml-2">
                                Jobs
                              </p>
                              <svg
                                className={`w-4 h-4 ml-1 transition-transform ${
                                  isJobsOpen ? "rotate-180" : ""
                                }`}
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M19 9l-7 7-7-7"
                                ></path>
                              </svg>
                            </div>

                            {isJobsOpen && (
                              <div className="absolute z-10 bg-white shadow-md rounded-md py-1 mt-1 w-40 left-0 md:left-0 right-0 md:right-auto mx-auto md:mx-0">
                                <NavLink
                                  to={item.routeLink1}
                                  className={({ isActive }) =>
                                    isActive
                                      ? "block px-4 py-2 text-[#036AD1] font-bold"
                                      : "block px-4 py-2 text-[#707070] hover:bg-gray-100"
                                  }
                                >
                                  Latest Jobs
                                </NavLink>
                                <NavLink
                                  to={item.routeLink}
                                  className={({ isActive }) =>
                                    isActive
                                      ? "block px-4 py-2 text-[#036AD1] font-bold"
                                      : "block px-4 py-2 text-[#707070] hover:bg-gray-100"
                                  }
                                >
                                  Applied Jobs
                                </NavLink>
                              </div>
                            )}
                          </div>
                        )}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </nav>
  );
}
