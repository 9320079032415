import React, { useEffect, useState, CSSProperties } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import courseicons from "../../images/photoshop.png";
import Header from "../elements/header";
import enroleImg from "../../images/enroleImg.svg";
import searchIcon from "../../images/searchIcon.svg";
import { StarRating } from "../elements/progressBar";
import ClipLoader from "react-spinners/ClipLoader";
import Footer from "../elements/Footer";

function TrendingCourse() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [courseDetails] = useState(state);
  const [search, setSearch] = useState("");
  const [courseState, setCourseState] = useState(false);
  let [loading, setLoading] = useState(true);
  const [loadmorebtn, setloadmorebtn] = useState(true);

  const override: CSSProperties = {
    flex: 1,
    marginTop: 240,
    justifyContent: "center",
    alignItems: "center",
  };

  const handletrendCourse = (props: any) => {
    navigate(`/course/${props.course_name.replace(/ /g, "-").toLowerCase()}`, {
      state: props,
    });
  };
  const searchresult = () => {
    if (search.length > 0) {
      return courseDetails.filter((obj: any) =>
        obj.course_name.toLowerCase().includes(search.toLowerCase())
      );
    }
    return courseDetails;
  };
  const convertToK = (number: number) => {
    if (number >= 1000) {
      const suffixes = ["", "k", "M", "B", "T"];
      const suffixIndex = Math.floor(Math.log10(number) / 3);
      const abbreviatedNumber = (number / Math.pow(1000, suffixIndex)).toFixed(
        1
      );
      return abbreviatedNumber + suffixes[suffixIndex];
    }
    return number.toString();
  };

  const [count, setCount] = useState(6);
  const handleLoad = () => {
    let data_Lenght = courseDetails?.map((item: any) => {
      return item;
    });
    setCount(data_Lenght?.length);
    setloadmorebtn(false);
  };
  useEffect(() => {
    setCourseState(true);
    courseDetails.length > count ? setloadmorebtn(true) : setloadmorebtn(false);
  }, []);

  let length = 15;
  return (
    <div>
      <Header />
      <>
        {courseState ? (
          <div>
            {/* <p className="mt-5 text-left ml-10 text-[30px] max-tablet:text-[30px] max-mobile:text-[22px] text-[#707070] font-bold">
              Trending Courses
            </p>
            <div className="flex mx-auto justify-center items-center">
              <input
                placeholder="Search..."
                className=" mb-5 rounded-[3px] shadow-3xl text-[14px] text-[#036AD1] mt-5 p-2  w-3/6 outline-none placeholder-[#036AD1]"
                onChange={(e) => setSearch(e.target.value)}
              ></input>
              <img
                className="bg-[#036AD1] p-2 shadow-3xl  w-[45px] h-[40px] max-tablet:w-[45px] max-tablet:h-[40px] max-mobile:h-[37px] max-mobile:w-[33px]"
                src={searchIcon}
                alt="icon"
              />
            </div> */}
            <p className="mt-5 text-left ml-10 text-[30px] max-tablet:text-[30px] max-mobile:text-[22px] text-[#707070] font-bold">
              Trending Courses
            </p>
            <div className="flex mx-auto justify-center items-center gap-2">
              <input
                placeholder="Search..."
                className="mb-5 rounded-md shadow-md text-[14px] text-[#036AD1] mt-5 p-2 w-3/6 outline-none placeholder-[#036AD1] border border-[#036AD1]"
                onChange={(e) => setSearch(e.target.value)}
              />
              <button className="bg-[#036AD1] p-2 shadow-md w-[45px] h-[40px] max-tablet:w-[45px] max-tablet:h-[40px] max-mobile:h-[37px] max-mobile:w-[33px] rounded-md flex justify-center items-center">
                <img src={searchIcon} alt="icon" className="w-5 h-5" />
              </button>
            </div>
            {/* <div className="grid grid-cols-3 px-1  max-laptop:grid-cols-2 max-mobile:grid-cols-1 p-6">
                {searchresult()
                  ?.slice(0, count)
                  .map((item: any) => {
                    return (
                      <div className="" onClick={() => handletrendCourse(item)}>
                        <div className="  items-center my-3 mx-auto object-cover w-[330px] h-[280px] bg-white border border-gray-200 rounded-lg shadow max-mobile:w-[65vw] max-mobile:h-[240px]">
                          <img
                            className="w-full h-[150px] max-tablet:h-[150px] max-mobile:h-[120px]"
                            src={item?.thumbnail?item?.thumbnail?item.thumbnail?.url:'':''}
                            alt="course thumbnil"
                          />
                          <p className="px-4 pt-3 border-t-2 text-left text-[21px]  max-mobile:text-[16px] font-medium text-[#707070] rounded-t-3xl">

                        
                            {item.course_name?.substring(0, length)}{item.course_name.length >15 ?'....':'..'}
                          </p>
                          <div className="ml-0 my-2 text-left px-4 flex items-center">
                            <p className="pr-3 max-mobile:pr-0 font-bold text-[12px] text-[#000000]">
                              {" "}
                              {item.star_rating}
                            </p>
                            <StarRating rating={5}
                            />
                          </div>
                          <div className="flex justify-between p-3 max-mobile:mt-0">
                            <p className=" text-[#007AFF] text-[14px] flex">
                              <img
                                className="w-[16px] h-[16px] mr-1"
                                src={enroleImg}
                                alt="enroleicon"
                              />{" "}
                              {convertToK(item.students_enrolled)} Enrolled
                            </p>
                            <p className=" text-[#007AFF] text-[14px] mr-3">
                              {item.duration} Hrs
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div> */}
            <div className="grid grid-cols-3 gap-6 px-6 max-laptop:grid-cols-2 max-mobile:grid-cols-1 py-10">
              {searchresult()
                ?.slice(0, count)
                .map((item: any) => (
                  <div
                    key={item.id}
                    className="cursor-pointer hover:scale-105 transition-transform duration-300"
                    onClick={() => handletrendCourse(item)}
                  >
                    <div className="bg-white border border-gray-200 rounded-2xl shadow-md overflow-hidden w-[95%]">
                      <img
                        className="w-full h-32 object-cover"
                        src={item?.thumbnail ? item.thumbnail?.url : ""}
                        alt="Course Thumbnail"
                      />
                      <div className="p-4 text-left">
                        <p className="text-lg font-semibold text-gray-800 truncate">
                          {item.course_name?.substring(0, 40)}
                          {item.course_name?.length > 15 ? "...." : ".."}
                        </p>
                        <div className="flex items-center gap-2 mt-2">
                          <p className="text-sm font-bold text-black">
                            {item.star_rating}
                          </p>
                          <StarRating rating={5} />
                        </div>
                        <div className="flex justify-between items-center mt-4 text-sm text-blue-500">
                          <p className="flex items-center gap-1">
                            <img
                              className="w-4 h-4"
                              src={enroleImg}
                              alt="Enroll Icon"
                            />
                            {convertToK(item.students_enrolled)} Enrolled
                          </p>
                          <p>{item.duration} Hrs</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            {/* {loadmorebtn ? (
              <button
                onClick={handleLoad}
                className="text-white text-center mt-5 w-44 max-tablet:w-44 max-mobile:w-32 max-mobile:mb-8 bg-gradient-to-r from-[#E91F00] via-[#E93C00] to-[#E95900]  font-medium rounded-md text-[20px] max-tablet:text-[20px] max-mobile:text-[14px] px-5 py-2.5 mb-2"
              >
                {" "}
                Load More{" "}
              </button>
            ) : (
              ""
            )} */}
            {loadmorebtn && (
              <button
                onClick={handleLoad}
                className="text-white text-center mt-5 w-44 max-tablet:w-44 max-mobile:w-32 max-mobile:mb-8 bg-gradient-to-r from-[#E91F00] via-[#E93C00] to-[#E95900] font-medium rounded-md text-[20px] max-tablet:text-[20px] max-mobile:text-[14px] px-5 py-2.5 mb-2"
              >
                Load More
              </button>
            )}
          </div>
        ) : (
          <div></div>
        )}
      </>
      <Footer />
    </div>
  );
}

export default TrendingCourse;
