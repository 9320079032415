import { Progressbar } from "./progressBar";
import Tickarrow from "../../images/Tickarrow.svg";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import calendaricon from "../../images/calendaricon.svg";
import feedIcon from "../../images/feedIcon.png";

import moment from "moment";
export type Props = {
  title: string;
  courseName: string;
  trainer?: string;
  branch?: string;
  city?: string;
  percentage: number;
};
export default function courseSessionCard({
  title,
  courseName,
  trainer,
  branch,
  city,
  percentage,
}: Props) {
  const classStyleP =
    "text-[20px] font-normal text-[#707070] max-mobile:text-[16px]";
  return (
    // <div className="flex items-center mx-auto text-left justify-between w-4/6 p-6 bg-white border border-gray-200 rounded-lg shadow max-laptop:w-5/6  ">
    //   <div className=" justify-items-center  align-middle ">
    //     <div className="text-[30px] max-tablet:text-[30px] max-mobile:text-[18px] font-bold text-[#707070]">
    //       {" "}
    //       {title}{" "}
    //     </div>
    //     <div>
    //       <p className={classStyleP}>
    //         <span className=" font-bold"> Course:</span> {courseName}
    //       </p>{" "}
    //     </div>
    //     {trainer == null ? (
    //       ""
    //     ) : (
    //       <div>
    //         <p className={classStyleP}>
    //           <span className=" font-bold">Trainer:</span> {trainer}
    //         </p>{" "}
    //       </div>
    //     )}
    //     {branch == null ? (
    //       ""
    //     ) : (
    //       <div>
    //         <p className={classStyleP}>
    //           <span className=" font-bold">Branch:</span> {branch},{city}{" "}
    //         </p>{" "}
    //       </div>
    //     )}
    //   </div>
    //   <div className="text-right justify-items-end align-middle max-mobile:justify-items-center ">
    //     <Progressbar
    //       percentage={percentage}
    //       className="w-[100px] h-[100px] max-mobile:w-[70px] max-mobile:h-[70px]"
    //     />
    //   </div>
    // </div>
    <div
      className="flex flex-wrap items-center mx-auto text-left justify-between w-4/6 p-6 bg-white border border-gray-200 rounded-lg shadow-lg 
                max-laptop:w-5/6 max-tablet:flex-col max-mobile:w-[90vw] max-mobile:p-4 transition-shadow hover:shadow-xl"
    >
      {/* Left Section - Course Details */}
      <div className="flex-1 space-y-3">
        <h2 className="text-[30px] max-tablet:text-[24px] max-mobile:text-[18px] font-bold text-[#036AD1]">
          {title}
        </h2>
        <div className="space-y-1">
          <p className={classStyleP}>
            <span className="font-bold">Course:</span> {courseName}
          </p>

          {trainer && (
            <p className={classStyleP}>
              <span className="font-bold">Trainer:</span> {trainer}
            </p>
          )}

          {branch && (
            <p className={classStyleP}>
              <span className="font-bold">Branch:</span> {branch}, {city}
            </p>
          )}
        </div>
      </div>

      {/* Right Section - Progress Bar */}
      <div className="flex justify-center items-center text-right max-tablet:mt-4 max-tablet:w-full">
        <Progressbar
          percentage={percentage}
          className="w-[100px] h-[100px] max-mobile:w-[70px] max-mobile:h-[70px]"
        />
      </div>
    </div>
  );
}

export type Prop = {
  subtitle: string;
  date: string;
  attendents: string;
  sessionMessage: string | any;
  data: any;
  sessionData: any;
};

export function SessionCard({
  subtitle,
  date,
  attendents,
  sessionMessage,
  data,
  sessionData,
}: Prop) {
  const navigate = useNavigate();
  const dateFormat = (dateText: string, dateFormat: string) => {
    let text;
    if (moment(dateText).format(dateFormat) !== "Invalid date") {
      text = moment(dateText, "YYYY/MM/DD").format("DD MMM YYYY");
    } else {
      text = moment(new Date()).format(dateFormat);
    }
    return text;
  };

  const dateValue = dateFormat(date, "YYYY/MM/DD");

  const [props, setProps] = useState({
    data: data,
    sessionData: sessionData,
    sessionMessage: sessionMessage,
    date: dateValue,
  });

  const handleClick = (props: any) => {
    navigate("/course-descpage", { state: props });
  };

  return (
    <div className=" mx-auto text-left justify-between mt-3 p-4 bg-white border border-gray-200 rounded-lg shadow  max-monitor:w-11/12 max-desktop:w-[350px] max-laptop:w-[350px] max-tablet:w-[320px] max-mobile:w-[80vw] max-mobile:mx-auto">
      <div className="flex justify-between  ">
        <div className=" justify-items-center align-middle mr-1 max-mobile:mr-0  ">
          <div className="text-[16px] font-medium text-[#707070] max-mobile:text-[14px] max-mobile:w-[60vw]">
            {" "}
            You are Marked{" "}
            {attendents == "Present" ? (
              <span className="text-[#036AD1] max-mobile:block">
                {attendents}
              </span>
            ) : (
              <span className="text-[#FF0000]">{attendents}</span>
            )}{" "}
            for the Session{" "}
          </div>
          <div>
            <p className="text-[20px] max-tablet:text-[20px] max-mobile:text-[14px] font-bold text-[#FB0000] md:text-[18px] ">
              {subtitle.length > 15 ? subtitle.slice(0, 20) + "..." : subtitle}
            </p>{" "}
          </div>
        </div>
        <div>
          <img
            className="w-[41px] h-[41px] max-tablet:w-[41px] max-tablet:h-[41px] max-mobile:w-[35px] max-mobile:h-[35px] mt-2"
            src={Tickarrow}
            alt="click"
          />
        </div>
      </div>
      <div className="flex items-center mt-5 justify-between align-middle ">
        <div className="flex items-center">
          <p className="text-[17px] max-mobile:text-[14px] font-normal text[#707070] md:text-[17px] flex items-center ">
            <span className=" font-bold pr-3">
              <img src={calendaricon} alt="date icon" />
            </span>{" "}
            {dateValue}
          </p>
        </div>
        <div className="max-mobile:text-[14px]">
          {sessionMessage === "Give Feedback" ? (
            <button
              className="flex items-center w-[140px] max-mobile:w-[120px] text-[#000000] p-1.5 rounded-lg bg-[#EFEFEF]"
              onClick={() => handleClick(props)}
            >
              {sessionMessage}{" "}
              <img className="pl-1" src={feedIcon} alt="icon" />
            </button>
          ) : (
            <button
              className=" w-[140px] max-mobile:w-[110px] text-[#000000] p-1.5 rounded-lg bg-[#EFEFEF]"
              onClick={() => handleClick(props)}
            >
              {sessionMessage}{" "}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
export type Prop1 = {
  title: string;
  courseName: string;
  trainer: string;
  branch: string;
  city?: string;
  date: any;
};
export function CourseDescCard({
  title,
  courseName,
  trainer,
  branch,
  city,
  date,
}: Prop1) {
  const dateFormat = (dateText: string, dateFormat: string) => {
    let text;
    if (moment(dateText).format(dateFormat) !== "Invalid date") {
      text = moment(dateText).format(dateFormat);
    } else {
      text = moment(new Date()).format(dateFormat);
    }
    return text;
  };

  const dateValue = dateFormat(date, "DD MMM YYYY");

  const classStyleP =
    "text-[20px] font-normal text-[#707070] max-mobile:text-[16px]";
  return (
    // <div className="flex items-center mt-5 mx-auto text-left justify-between w-4/6 p-6 bg-white border border-gray-200 rounded-lg shadow-3xl max-laptop:w-5/6 max-mobile:flex">
    //   <div className=" justify-items-center  align-middle ">
    //     <div className="text-[30px] font-medium text-[#000000] max-mobile:text-[20px]">
    //       {" "}
    //       {title}{" "}
    //     </div>
    //     <div>
    //       <p className={classStyleP}>
    //         <span className=" font-bold"> Course:</span> {courseName}
    //       </p>{" "}
    //     </div>
    //     <div>
    //       <p className={classStyleP}>
    //         <span className=" font-bold">Trainer:</span> {trainer}
    //       </p>{" "}
    //     </div>
    //     <div>
    //       <p className={classStyleP}>
    //         <span className=" font-bold">Branch:</span> {branch},{city}{" "}
    //       </p>{" "}
    //     </div>
    //   </div>
    //   <div className="text-right max-mobile:block justify-items-end items-center pt-6 justify-end  max-mobile:mt-5 max-mobile:justify-start">
    //     <img
    //       className="w-[100px] h-[100px] -mt-7 max-mobile:w-[50px] max-mobile:h-[50px] max-mobile:ml-[50%] max-mobile:-mt-[52%]"
    //       src={Tickarrow}
    //       alt="tick icon"
    //     />
    //     <p className="text-[#707070] text-[14px] font-medium mt-2">
    //       Session Date
    //     </p>
    //     <p className=" text-[#FF0000] text-[16px] font-bold">{dateValue}</p>
    //   </div>
    // </div>
    <div className="flex items-center gap-6 mt-5 mx-auto text-left justify-between w-4/6 p-6 bg-white border border-gray-200 rounded-lg shadow-xl hover:shadow-2xl transition-all duration-300 max-laptop:w-5/6 max-mobile:flex max-mobile:flex-col max-mobile:items-start">
      {/* Left Section - Course Info */}
      <div className="flex-1 space-y-4">
        <h2 className="text-[30px] font-bold text-[#036AD1] max-mobile:text-[22px]">
          {title}
        </h2>
        <div className="space-y-1">
          <p className={`${classStyleP} flex items-center gap-2`}>
            <span className="font-bold text-[#000000]">Course:</span>
            {courseName}
          </p>

          <p className={`${classStyleP} flex items-center gap-2`}>
            <span className="font-bold text-[#000000]">Trainer:</span>
            {trainer}
          </p>

          <p className={`${classStyleP} flex items-center gap-2`}>
            <span className="font-bold text-[#000000]">Branch:</span>
            {branch}, {city}
          </p>
        </div>
      </div>

      {/* Right Section - Icon & Date */}
      <div className="text-right flex flex-col items-center justify-center max-mobile:w-full max-mobile:text-left">
        <img
          className="w-[100px] h-[100px] mb-3 max-mobile:w-[70px] max-mobile:h-[70px]"
          src={Tickarrow}
          alt="tick icon"
        />
        <p className="text-[#707070] text-[14px] font-medium">Session Date</p>
        <p className="text-[#FF0000] text-[18px] font-bold">{dateValue}</p>
      </div>
    </div>
  );
}
