// import React, { useEffect, useState } from "react";
// import LeftNav from "../elements/leftNav";
// import Header from "../elements/header";
// import RightScreen from "./rightScreen";
// import { useLocation } from "react-router-dom";
// import Footer from "../elements/Footer";

// function Profile() {
//   const { state } = useLocation();
//   const data = state;
//   return (
//     <div>
//       <Header />
//       <div className="flex justify-between">
//         <LeftNav />
//         <RightScreen data={data?.myState} />
//       </div>
//       <Footer />
//     </div>
//   );
// }

// export default Profile;

import React from 'react';
import LeftNav from '../elements/leftNav';
import Header from '../elements/header';
import RightScreen from './rightScreen';
import { useLocation } from 'react-router-dom';
import Footer from '../elements/Footer';

function Profile() {
  const { state } = useLocation();
  const data = state;

  return (
    <div className="min-h-screen flex flex-col">
      {/* Header */}
      <Header />

      {/* Main Content Area */}
      <div className="flex flex-1">
        {/* Left Navigation (Sticky) */}
        <div className="w-1/4 max-lg:w-1/3 max-md:w-1/2 max-sm:hidden sticky top-0 h-screen overflow-y-auto">
          <LeftNav />
        </div>

        {/* Right Content (Scrollable) */}
        <div className="flex-1 h-[calc(100vh-80px)] overflow-y-auto">
          <RightScreen data={data?.myState} />
        </div>
      </div>

      {/* Footer */}
      <Footer />
    </div>
  );
}

export default Profile;
