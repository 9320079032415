import downloadImg from "../../images/downloadImg.svg";
import lockImg from "../../images/lockImg.svg";
import locationicon from "../../images/locationicon.svg";
import speakericon from "../../images/speakericon.svg";
import calendaricon from "../../images/calendaricon.svg";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { feedback_data } from "../../redux/useReducer";

export type Props = {
  value: any;
};

export default function cards({ value }: Props) {
  return (
    <div className="flex -mt-40 align-middle mx-auto text-center justify-center max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
      <p>{value}</p>
    </div>
  );
}

export type Prop = {
  title: string;
  coursePercentage: string | number;
  fileName: string;
  show_percentage: number | string;
  url1: any;
};

export function Materialcards({
  title,
  coursePercentage,
  fileName,
  show_percentage,
  url1,
}: Prop) {
  const dispatch = useDispatch();
  const handleDownload = () => {
    const url = url1;
    // Replace with your file or image URL
    const link = document.createElement("a");
    link.href = url + "#toolbar=0";
    link.href = url + "#toolbar=0";
    link.target = "_blank";
    link.click();
  };

  const handleClick = () => {
    dispatch(
      feedback_data({
        data: true,
        value: show_percentage,
      })
    );
  };

  return (
    // <div className="flex cursor-pointer justify-between mx-auto text-left p-6 bg-white border border-gray-200 rounded-lg shadow ">
    //   <div>
    //     <p className="text-[22px] font-medium text-[#707070]">
    //       How was your session on
    //     </p>
    //     <p className="text-[20px] text-[#FB0000] font-medium">{title}</p>
    //   </div>
    //   <div className=" ml-5 flex items-center">
    //     {show_percentage <= coursePercentage ? (
    //       <img
    //         onClick={handleDownload}
    //         className="w-[45px] h-[45px]"
    //         src={downloadImg}
    //         alt="download img"
    //       />
    //     ) : (
    //       <img
    //         onClick={() => handleClick()}
    //         className="w-[45px] h-[45px]"
    //         src={lockImg}
    //         alt="lock Img"
    //       />
    //     )}
    //   </div>
    // </div>
    <div
      className="flex flex-wrap items-center justify-between mx-auto text-left p-6 bg-white border border-gray-200 rounded-lg shadow-lg 
                max-w-[600px] max-tablet:w-[90%] max-mobile:flex-col max-mobile:items-start max-mobile:p-4 transition-shadow hover:shadow-xl"
    >
      {/* Left Section - Session Details */}
      <div className="flex-1 space-y-1">
        <p className="text-[22px] max-tablet:text-[20px] max-mobile:text-[18px] font-medium text-[#707070]">
          How was your session on
        </p>

        <p className="text-[20px] max-tablet:text-[18px] max-mobile:text-[16px] text-[#FB0000] font-medium">
          {title}
        </p>
      </div>

      {/* Right Section - Icon (Download/Lock) */}
      <div className="flex items-center max-mobile:mt-4">
        <img
          onClick={
            show_percentage <= coursePercentage ? handleDownload : handleClick
          }
          className="w-[45px] h-[35px] max-mobile:w-[40px] max-mobile:h-[40px] cursor-pointer"
          src={show_percentage <= coursePercentage ? downloadImg : lockImg}
          alt={
            show_percentage <= coursePercentage ? "Download Icon" : "Lock Icon"
          }
        />
      </div>
    </div>
  );
}

export type data = {
  title: string;
  image: any;
  desc: any;
  subType: "";
  data: any;
  content_type: "";
};

export function NotificationCard({
  title,
  image,
  desc,
  subType,
  data,
  content_type,
}: data) {
  const navigate = useNavigate();
  const [props, setProps] = useState({
    title: title,
    image: image,
    desc: desc,
    subType: subType,
    data: data,
    content_type: content_type,
  });
  const handleClick = () => {
    if (content_type !== "") {
      navigate(`/resourses/${props.title.replace(/ /g, "-")} `, {
        state: props,
      });
    } else {
      navigate("/notificationdetails", { state: props });
    }
  };
  // {content_type?content_type=="Instant Push Content"?"Instant Push Content":"Predefined Push Content":'all'}
  return (
    <div
      onClick={() => handleClick()}
      className="flex justify-between mx-auto cursor-pointer text-left shadow-3xl max-w-sm p-6 max-mobile:p-2 bg-white border border-gray-200 rounded-lg dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
    >
      <div className="flex items-center max-mobile:w-[50vw]">
        <p className="max-mobile:text-[12px]">{title}</p>
      </div>
      {/* <div className='flex items-center p-5'>
        <p className='text-[#036AD1]'>{content_type}</p>

        </div> */}
      <img
        style={{ objectFit: "cover" }}
        className="w-[200px] h-[100px]  max-tablet:w-[140px] max-tablet:h-[100px] max-mobile:h-[13vh] max-mobile:w-[50vw] "
        src={image}
        alt="download img"
      />
    </div>
  );
}

export type detail = {
  subtitle: string;
  classStyle: string;
  title: string;
  status: string | boolean;
  date: string;
  location: string;
};
export function ApplyjobCard({
  subtitle,
  classStyle,
  title,
  status,
  date,
  location,
}: detail) {
  var color: string = "";
  if (status === "shortlisted") {
    color = "#0BAB00";
  } else if (status === "not shortlisted") {
    color = "#FF0000";
  } else if (status === "Shortlisted But Not Placed") {
    color = "#FFCA00";
  } else {
    color = "#EFEFEF";
  }

  return (
    <div
      className={`block align-middle mx-auto cursor-pointer text-center justify-center  p-6  bg-white border border-gray-200 rounded-lg shadow  max-mobile:w-[70vw]  ${classStyle}  `}
    >
      <div className="flex justify-between items-center">
        <div className="flex items-center">
          <img className="w-[13px] h-[15px]" src={locationicon} alt="icon" />
          <p className=" text-[18px] max-mobile:text-[14px] text-[#707070] font-normal pl-2">
            {location}
          </p>
        </div>
        <img className=" w-[40px] h-[40px]  p-1" src={speakericon} alt="icon" />
      </div>
      <p className="text-left text-[16px] max-mobile:text-[14px] text-[#000000] ">
        {title}
      </p>
      <p className="text-left text-[16px] text-[#707070] ">{subtitle}</p>
      <div className=" flex justify-between mt-5">
        <div className="flex items-center">
          <img className=" w-[15px] h-[15px]" src={calendaricon} alt="icon" />
          <p className="pl-2 text-[11px] text-[#000000]">{date}</p>
        </div>
        <div>
          {status !== "" ? (
            status === "View Job" ? (
              <p
                className={`text-[#000000] ${`bg-[${color}]`} hover:bg-[#036AD1] hover:text-white border-2 p-2 w-[95px] max-mobile:w-[25vw] text-[14px] rounded-md`}
                // style={{ backgroundColor: color }}
              >
                {status}
              </p>
            ) : (
              <p
                className={`text-[#000] ${`bg-[${color}]`} hover:bg-[#036AD1] hover:text-white max-mobile:p-1 max-tablet:p-2 p-2 w-[150px] max-tablet:w-[150px] max-mobile:w-[26vw] text-[14px] rounded-md`}
                // style={{ backgroundColor: color }}
              >
                {" "}
                {status}{" "}
              </p>
            )
          ) : (
            <p></p>
          )}
        </div>
      </div>
    </div>
  );
}

export type details = {
  title: string;
  location: string;
  status: string;
  subtitle: string;
  date: string;
  opening: string;
};
export function ApplyjobCardDesc({
  location,
  title,
  date,
  status,
  subtitle,
  opening,
}: details) {
  var color: string = "";
  if (status === "shortlisted") {
    color = "#0BAB00";
  } else if (status === "not shortlisted") {
    color = "#FF0000";
  } else if (status === "Shortlisted But Not Placed") {
    color = "#FFCA00";
  } else {
    color = "#EFEFEF";
  }

  return (
    <div className="block align-middle  mx-auto my-3 text-center justify-center w-8/12 max-tablet:w-10/12 max-mobile:w-11/12 p-6 bg-[#F9FCFF]  rounded-lg shadow ">
      <div className="flex justify-between">
        <p className="text-left text-[30px]  max-mobile:text-[24px] text-[#000000] ">
          {title}
        </p>
        <img className=" w-[40px] h-[40px]  p-1" src={speakericon} alt="icon" />
      </div>
      <p className="text-left text-[20px] max-mobile:text-[18px] text-[#707070] ">
        {subtitle}
      </p>
      <div className="my-3">
        <p className="text-left text-[20px]  max-mobile:text-[18px] text-[#000000]">
          No. of Openings:<span className=" text-[#707070]"> {opening}</span>
        </p>
        {/* <p className='text-left text-[20px]   max-mobile:text-[18px] text-[#000000]'>Salary: <span className=' text-[#707070]'> {salary} </span></p> */}
      </div>
      <div className=" flex justify-between ">
        <div className="flex items-center">
          <img className="w-[20px] h-[20px]" src={locationicon} alt="icon" />
          <p className=" text-[20px]  max-mobile:text-[18px] font-normal pl-2">
            {location}
          </p>
        </div>
        <div className="block justify-end -mt-10 max-mobile:-mt-12">
          {status !== "" ? (
            <p
              className="text-[#000] ml-5 border-2 p-2 w-[95px] text-[15px] max-mobile:p-1 mb-3 rounded-md"
              style={{ backgroundColor: color }}
            >
              {status}
            </p>
          ) : (
            <p className=" mt-8"></p>
          )}
          <div className="flex items-center">
            <img className=" w-[15px] h-[15px]" src={calendaricon} alt="icon" />
            <p className="pl-2 text-[20px]  max-mobile:text-[18px] text-[#000000]">
              {date}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
