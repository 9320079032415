// import Mycourse from './mycourse'
// import Mycertificate from './mycertificate'
// import Myplacement from './myplacement'
// import Feedback from './feedback';
// import Editprofile from './editprofile';
// import Uploadresume from './Uploadresume';

// function rightScreen({ data }: any) {

//   let routelink = data
//   return (
//     <div className=' w-full mt-1 bg-[#F9FCFF]'>
//       {routelink === 'mycourse' ? <Mycourse /> : routelink === 'mycertificate' ? <Mycertificate /> : routelink === 'myplacementsupport' ? <Myplacement /> : routelink === 'feedback' ? <Feedback /> : routelink === 'editprofile' ? <Editprofile /> : routelink === 'Resume' ? <Uploadresume />: <div></div>}
//     </div>
//   )
// }

// export default rightScreen

import Mycourse from './mycourse'
import Mycertificate from './mycertificate'
import Myplacement from './myplacement'
import Feedback from './feedback';
import Editprofile from './editprofile';
import Uploadresume from './Uploadresume';

// RightScreen.jsx - Updated
function rightScreen({ data }: any) {
  let routelink = data
  return (
    <div className="w-full mt-1 bg-[#F9FCFF] min-h-[calc(100vh-header-height-footer-height)] overflow-y-auto">
      {routelink === 'mycourse' ? <Mycourse /> : 
       routelink === 'mycertificate' ? <Mycertificate /> : 
       routelink === 'myplacementsupport' ? <Myplacement /> : 
       routelink === 'feedback' ? <Feedback /> : 
       routelink === 'editprofile' ? <Editprofile /> : 
       routelink === 'Resume' ? <Uploadresume /> : 
       <div></div>}
    </div>
  )
}

export default rightScreen