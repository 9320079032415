import { useRef, useEffect, useState } from "react";
import rightArrow from "../../images/rightArrow.svg";
import { getDashBoardData } from "../../function/Student/dasboard";
import { getSessionsByStudentUid } from "../../function/Student/CourseProgree/CourseProgress";
import { useNavigate } from "react-router-dom";

function Resource() {
  const scrollRef: any = useRef();
  const uuid: any = localStorage.getItem("uid");
  const [resource, setresource] = useState() as any;
  const [courseData, setCourseData] = useState({}) as any;
  const [resoursestate, setresourseState] = useState(false);
  const navigate = useNavigate();

  const jobdetailsData = async () => {
    var data = await getDashBoardData(uuid);
    setresource(data);
    const course_data = await getSessionsByStudentUid(uuid);

    course_data ? setCourseData(course_data) : setCourseData([]);
    setresourseState(true);
  };
  const handleClick = (props: any) => {
    navigate("/resource", {
      state: {
        resourceId: props.id,
        courseId: courseData?.courseDetails?.course_id,
      },
    });
  };

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
    });
    jobdetailsData();
  }, []);

  return (
    <div>
      <p className=" text-left mt-5 pl-6 -mb-14 text-[#707070] text-[30px] font-medium max-mobile:text-[20px] max-mobile:mb-4">
        Resources
      </p>
      <div className="flex items-center justify-evenly px-10  space-x-5 h-[400px] max-tablet:h-[400px] max-mobile:h-auto max-mobile:px-4 max-mobile:space-x-1 ">
        <img
          src={rightArrow}
          alt="ScrollY"
          className="rotate-180 cursor-pointer max-mobile:hidden"
          onClick={() => (scrollRef.current.scrollLeft -= 1000)}
        />
        {resoursestate ? (
          <div
            className={`mx-auto  px-6 flex space-x-4 overflow-x-auto max-mobile:flex-wrap scrollBar  snap-x snap-mandatory  scroll-smooth  `}
            ref={scrollRef}
          >
            {resource.resources.length ? (
              resource.resources.map((item: any, index: any) => {
                return (
                  <div
                    key={index}
                    className="p-5 max-tablet:p-5 max-mobile:py-2 max-mobile:basis-[47%]  max-mobile:!ml-0 max-mobile:mr-[3%] bg-[#F8FFFE] my-4 shadow-3xl cursor-pointer"
                    onClick={() => handleClick(item)}
                  >
                    <div className="w-[240px] max-mobile:w-[20vw]">
                      <img
                        className="flex mt-5 max-tablet:mt-5 max-mobile:mt-1 mx-auto justify-center aspect-[5/3]"
                        src={item.thumbnail}
                        alt="icon"
                      />
                      <p className=" mt-3 text-center w-[100%] break-words text-[20px] max-tablet:[20px] max-mobile:text-[14px] text-[#707070] font-normal">
                        {item.category_name}
                      </p>
                    </div>
                  </div>
                );
              })
            ) : (
              <div></div>
            )}
          </div>
        ) : (
          <div></div>
        )}
        <img
          src={rightArrow}
          alt="ScrollY"
          className=" cursor-pointer  max-mobile:hidden"
          onClick={() => (scrollRef.current.scrollLeft += 1000)}
        />
      </div>
    </div>
  );
}

export default Resource;
