import React, { useEffect, useState, useRef } from "react";
import Header from "../elements/header";
import Resource from "./Resource";
import JobOpening from "./JobOpening";
import DreamJob from "./dreamjob";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { training_idData } from "../../redux/useReducer";
import { studentPaymentDetailsBasedOnCourse } from "../../function/Student/CourseProgree/CourseProgress";
import CourseProgress from "./courseProgress";
import { getCourses } from "../../function/Student/Profile/Profile";
import { RingLoader } from "react-spinners";
import Footer from "../elements/Footer";
import rightArrow from "../../images/rightArrow.svg";

interface PaymentSummary {
  course_name: string;
  total_fee: number;
  paid_amount: number;
  balance_due: number;
}

interface CourseDetails {
  payment_summary: PaymentSummary[];
  id: string;
}

function Homepage() {
  const stud_uuid: any = localStorage.getItem("uid");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [courseDetails, setCourseDetails] = useState<CourseDetails | null>(
    null
  );
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const fetchData = async () => {
    setLoading(true);
    try {
      if (stud_uuid) {
        const course_data = await getCourses(stud_uuid);
        setCourseDetails(course_data);
        Coursevalue(course_data);

        const response = await studentPaymentDetailsBasedOnCourse(stud_uuid);

        if (Array.isArray(response) && response.length > 0) {
          setCourseDetails((prevState) => ({
            ...(prevState ?? { id: "" }),
            payment_summary: response.map((course: any) => ({
              course_name: course.course_name,
              total_fee: course.total_fee,
              paid_amount: course.paid_amount,
              balance_due: course.balance_due,
            })),
          }));
        }
      } else {
        navigate("/");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const Coursevalue = (props: any) => {
    dispatch(
      training_idData({
        data: props.id,
      })
    );
  };

  useEffect(() => {
    fetchData();
  }, [stud_uuid]);

  const handlePayNowClick = (courseName: string) => {
    console.log(`Course Name: ${courseName}`);
  };

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: -300, // Adjust the value for how much you want to scroll
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: 300, // Adjust the value for how much you want to scroll
        behavior: "smooth",
      });
    }
  };

  // Safely access courseDetails.payment_summary with a fallback to an empty array
  const filteredPaymentSummary = (courseDetails?.payment_summary || []).filter(
    (summary) => summary.balance_due > 0
  );

  // console.log(filteredPaymentSummary, "filteredPaymentSummary", courseDetails)

  return (
    // <div>
    //   {!loading ? (
    //     <>
    //       <Header />
    //       <p className="text-[45px] max-laptop:text-[40px] max-tablet:text-[30px] max-mobile:text-[18px] font-normal font-[roboto] text-left text-[#707070] ml-5 mt-10">
    //         Stay Ahead with{" "}
    //         <span className="font-bold text-[#000]">FITA Academy</span>{" "}
    //       </p>
    //       {filteredPaymentSummary.length > 0 && (
    //         <div className="relative px-5 py-4">
    //           <button
    //             className="absolute left-0 top-1/2 transform -translate-y-1/2 rotate-180 w-[22px] ml-3"
    //             onClick={scrollLeft}
    //           >
    //             <img src={rightArrow} alt="Left Arrow" style={{ width: '40px', height: '40px' }} /> {/* Adjust size here */}
    //           </button>
    //           <div ref={scrollContainerRef} className="flex flex-nowrap overflow-x-auto scrollbar-hide">
    //             {filteredPaymentSummary.map((summary, index) => (
    //               <div
    //                 key={index}
    //                 className="flex items-center bg-white w-[430px] p-4 text-left flex-shrink-0 ml-3"
    //               >
    //                 <div className="flex-shrink-0 w-[5px] h-[60px]" style={{ backgroundColor: '#F87171' }} />
    //                 <div className="flex-1 shadow h-[60px] place-content-center">
    //                   <h1 className="font-medium text-red-600 ml-3">
    //                     <span>Payment Overdue : </span>&#8377; {summary.balance_due.toFixed(2)}
    //                   </h1>
    //                   <p className="text-gray-500 ml-3 text-xs"><span>{summary.course_name} </span></p>
    //                 </div>
    //                 <button className="-ml-[90px] bg-blue-600 text-white rounded hover:bg-blue-700 transition px-2 py-1 text-sm" onClick={() => handlePayNowClick(summary.course_name)}>
    //                   Pay Now
    //                 </button>
    //               </div>
    //             ))}
    //           </div>
    //           <button
    //             className="absolute right-0 top-1/2 transform -translate-y-1/2 mr-3 -mt-3"
    //             onClick={scrollRight}
    //           >
    //             <img src={rightArrow} alt="Right Arrow" />
    //           </button>
    //         </div>
    //       )}
    //       <DreamJob />
    //       {courseprogess ? (
    //         <div className="bg-[#F9FCFF] text-left">
    //           <CourseProgress />
    //         </div>
    //       ) : (
    //         <div></div>
    //       )}
    //       <Jobopening />
    //       <Reasource />
    //     </>
    //   ) : (
    //     <ClipLoader color="#036AD1" loading={loading} cssOverride={override} />
    //   )}
    //   <Footer />
    // </div>
    <div className="min-h-screen flex flex-col">
      {!loading ? (
        <>
          <Header />
          <p className="text-4xl max-lg:text-3xl max-md:text-2xl max-sm:text-base font-normal font-[roboto] text-left text-[#707070] ml-5 mt-10">
            Stay Ahead with{" "}
            <span className="font-bold text-[#000]">FITA Academy</span>
          </p>

          {filteredPaymentSummary.length > 0 && (
            <div className="relative px-5 py-4">
              <button
                className="absolute left-0 top-1/2 -translate-y-1/2 rotate-180 w-[22px] ml-3"
                onClick={scrollLeft}
                aria-label="Scroll Left"
              >
                <img src={rightArrow} alt="Left Arrow" className="w-10 h-10" />
              </button>

              <div
                ref={scrollContainerRef}
                className="flex flex-nowrap overflow-x-auto scrollbar-hide gap-3"
              >
                {filteredPaymentSummary.map((summary, index) => (
                  <div
                    key={index}
                    className="flex items-center bg-white w-[430px] p-4 text-left flex-shrink-0 shadow-md rounded-lg"
                  >
                    <div className="flex-shrink-0 w-1 h-[60px] bg-red-400" />
                    <div className="flex-1 h-[60px] ml-3">
                      <h1 className="font-medium text-red-600 text-lg max-md:text-base">
                        Payment Overdue: ₹{summary.balance_due.toFixed(2)}
                      </h1>
                      <p className="text-gray-500 text-sm max-md:text-xs">
                        {summary.course_name}
                      </p>
                    </div>
                    <button
                      className="ml-auto bg-blue-600 text-white rounded hover:bg-blue-700 transition px-2 py-1 text-sm"
                      onClick={() => handlePayNowClick(summary.course_name)}
                    >
                      Pay Now
                    </button>
                  </div>
                ))}
              </div>
              <button
                className="absolute right-0 top-1/2 -translate-y-1/2 mr-3"
                onClick={scrollRight}
                aria-label="Scroll Right"
              >
                <img src={rightArrow} alt="Right Arrow" className="w-10 h-10" />
              </button>
            </div>
          )}
          <DreamJob />
          {/* {courseProgress && ( */}
          <div className="bg-[#F9FCFF] text-left">
            <CourseProgress />
          </div>
          {/* )} */}
          <JobOpening />
          <Resource />
        </>
      ) : (
        <div className="min-h-screen flex items-center justify-center">
          <RingLoader />
        </div>
      )}
      <Footer />
    </div>
  );
}

export default Homepage;
