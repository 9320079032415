// import { useEffect, useState } from 'react'
// import profileEditIcon from '../../images/profileEditicon.svg'
// import Graterthansymbol from '../../images/Graterthansymbol.svg'
// import whiteGraterthansymbol from '../../images/whitegraterthansymbol.svg'
// import Logouticon from '../../images/logouticon.svg'
// import navData from '../../content/leftNavData.json'
// import { useNavigate, NavLink } from 'react-router-dom'
// import haveQureies from '../../images/haveQureies.svg'
// import callusblack from '../../images/callusBlack.svg'
// import { getProfileDetails } from '../../function/Student/Profile/Profile'


// export const Iconbadge = (props: any) => {
//     if (window.location.pathname === `/profile/${props.props.routeLink}`) {
//         return <img className=' w-[20px] h-[20px]' src={props.props.colorIcon} alt='icon' />
//     }
//     return <img className=' w-[20px] h-[20px]' src={props.props.icon} />
// }

// export const Arrow = (props: any) => {
//     if (window.location.pathname === `/profile/${props.props.routeLink}`) {
//         return <img className='w-[10px] h-[20px] mr-1' src={whiteGraterthansymbol} alt='icon' />
//     }
//     return <img className='w-[10px] h-[20px] mr-1' src={Graterthansymbol} />
// }

// function LeftNav() {
//     const uuid:any = localStorage.getItem('uid')
//     const navigate = useNavigate()

//     async function signout() {
//         window.localStorage.clear();
//         localStorage.removeItem('uid')
//         navigate('')
//     }
//     const [userData, setUserdata] = useState({}) as any

//     const data = async () => {
//         const proileData = await getProfileDetails(uuid)
//         setUserdata(proileData)
//         title(proileData)
//     }
//     const [firstLetter, setFirstLetter] = useState('') 

//     const title = async (props: any) => {
//         let title_str = ''
//         await props?.name?.split(' ').forEach((element: any) => {
//             title_str += element[0]?.toUpperCase();
//         })
//         setFirstLetter(title_str)
//     }

//     useEffect(() => {
//         data()
//     }, [])

//     return (
//         <div className='sticky  max-monitor:w-[20%] max-monitor:top-[105px] max-desktop:w-[30%] max-desktop:top-[105px] max-laptop:w-[34%] max-laptop:top-[80px] max-tablet:w-[37%] max-tablet:top-[85px] max-mobile:w-[39%] max-mobile:top-[105px] bg-[#F9FCFF] cursor-pointer  max-mobile:hidden overflow-y-auto snap-y snap-mandatory scrollBar scrollbar-hide scroll-smooth monitor:h-[84vh] desktop:h-[89vh] laptop:h-[84vh]'>
//             <p className='text-left px-8 text-[30px] text-[#707070]  max-desktop:text-[24px] max-tablet:text-[22px] mt-2 mb-2'>My Account</p>
//             <NavLink
//                 to={`/profile/editprofile`}
//                 state={{ myState: 'editprofile' }}
//                 className={({ isActive }) => isActive ? ' outline-none font-medium text-[#000000] ' : 'bg-[#F9FCFF] font-normal text-[#3B3B3B]'}
//             ><div className='flex items-center justify-between p-2 bg-[#FFFFFF] mx-3 shadow-3xl rounded-lg mb-3'>
//                     <div className='flex px-2 items-center '>
//                         <p className=' bg-[#036AD1] text-[#fff] rounded-full p-1.5 w-[35px] h-[35px]'>{firstLetter}</p>
//                         <p className='px-5 text-[18px]'>{userData?.name?.split(' ')[0]}</p>
//                     </div>
//                     <img className='w-[35px] h-[35px] max-laptop:w-[25px] max-laptop:h-[25px]' src={profileEditIcon} alt='edit icon' />
//                 </div>
//             </NavLink>
//             <div className='w-full'>
//                 {navData.map((data, index) => {
//                     return <div className='flex items-center justify-between  px-5 py-1'
//                         key={index} >
//                         <NavLink to={`/profile/${data.routeLink}`} state={{ myState: `${data.routeLink}` }} className={({ isActive }) => isActive ? ' flex p-2 items-center text-left relative w-full justify-between bg-[#036AD1] text-[#fff]  rounded-md' : ' bg-[#F9FCFF] text-left flex justify-between font-normal p-2 items-center relative w-full text-[#3B3B3B]'} >
//                             <div className=' items-center flex'>
//                                 <Iconbadge props={data} />
//                                 <p className='px-5 max-desktop:text-[18px] max-tablet:text-[18px] text-left max-laptop:text-[14px] '>{data.linkTitle}</p>
//                             </div>
//                             <Arrow props={data} />
//                         </NavLink>
//                     </div>
//                 })}
//             </div>
//             <div className='flex my-2 px-8 item-center' onClick={signout}>
//                 <img className='w-[20px] h-[20px] mt-1' src={Logouticon} alt='icon' />
//                 <p className=' text-[20px] pl-5 max-laptop:text-[14px]  text-[#FF0000] '>Logout</p>
//             </div>
//             <div className='flex px-8 p-2'>
//                 <img src={haveQureies} alt='icon' />
//                 <p className=' text-[24px]  max-laptop:text-[14px]  font-bold text-[#000000] px-5 max-laptop:px-2 '>Have Queries</p>
//             </div>
//             <div className='flex mb-5 bg-[#F6F6F6] w-fit py-2 ml-20 px-4 rounded-lg'>
//                 <img src={callusblack} alt='icon' />
//                 <p className=' pl-3 text-[#000]'> Call Us</p>
//             </div>
//         </div>
//     )
// }

// export default LeftNav

import { useEffect, useState } from 'react';
import profileEditIcon from '../../images/profileEditicon.svg';
import Graterthansymbol from '../../images/Graterthansymbol.svg';
import whiteGraterthansymbol from '../../images/whitegraterthansymbol.svg';
import Logouticon from '../../images/logouticon.svg';
import navData from '../../content/leftNavData.json';
import { useNavigate, NavLink } from 'react-router-dom';
import haveQureies from '../../images/haveQureies.svg';
import callusblack from '../../images/callusBlack.svg';
import { getProfileDetails } from '../../function/Student/Profile/Profile';

export const Iconbadge = ({ props }: any) => (
    <img
        className='w-5 h-5 max-md:w-4 max-md:h-4 max-sm:w-3 max-sm:h-3'
        src={window.location.pathname === `/profile/${props.routeLink}` ? props.colorIcon : props.icon}
        alt='icon'
    />
);

export const Arrow = ({ props }: any) => (
    <img
        className='w-2.5 h-5 mr-1 max-md:w-2 max-md:h-4 max-sm:w-1.5 max-sm:h-3'
        src={window.location.pathname === `/profile/${props.routeLink}` ? whiteGraterthansymbol : Graterthansymbol}
        alt='icon'
    />
);

function LeftNav() {
    const uuid: any = localStorage.getItem('uid');
    const navigate = useNavigate();
    const [userData, setUserData] = useState({}) as any;
    const [firstLetter, setFirstLetter] = useState('');

    async function signout() {
        window.localStorage.clear();
        localStorage.removeItem('uid');
        navigate('/');
    }

    const data = async () => {
        const profileData = await getProfileDetails(uuid);
        setUserData(profileData);
        title(profileData);
    };

    const title = async (props: any) => {
        let title_str = '';
        await props?.name?.split(' ').forEach((element: any) => {
            title_str += element[0]?.toUpperCase();
        });
        setFirstLetter(title_str);
    };

    useEffect(() => {
        data();
    }, []);

    return (
        <div className='sticky w-full max-lg:w-[30%] max-md:w-[40%] max-sm:w-[50%] bg-[#F9FCFF] cursor-pointer overflow-y-auto snap-y snap-mandatory scrollBar scrollbar-hide scroll-smooth h-[84vh]'>
            <p className='text-left px-8 text-[28px] max-lg:text-[24px] max-md:text-[20px] max-sm:text-[18px] text-[#707070] mt-2 mb-2'>My Account</p>
            <NavLink
                to={`/profile/editprofile`}
                state={{ myState: 'editprofile' }}
                className={({ isActive }) => isActive ? 'outline-none font-medium text-[#000000]' : 'bg-[#F9FCFF] font-normal text-[#3B3B3B]'}
            >
                <div className='flex items-center justify-between p-2 bg-[#FFFFFF] mx-3 shadow-3xl rounded-lg mb-3'>
                    <div className='flex px-2 items-center'>
                        <p className='bg-[#036AD1] text-[#fff] rounded-full p-1.5 w-[35px] h-[35px] max-md:w-[30px] max-md:h-[30px] max-sm:w-[25px] max-sm:h-[25px]'>{firstLetter}</p>
                        <p className='px-5 text-[18px] max-md:text-[16px] max-sm:text-[14px]'>{userData?.name?.split(' ')[0]}</p>
                    </div>
                    <img className='w-8 h-8 max-md:w-6 max-md:h-6 max-sm:w-5 max-sm:h-5' src={profileEditIcon} alt='edit icon' />
                </div>
            </NavLink>
            <div className='w-full'>
                {navData.map((data, index) => (
                    <div className='flex items-center justify-between px-5 py-1' key={index}>
                        <NavLink
                            to={`/profile/${data.routeLink}`}
                            state={{ myState: `${data.routeLink}` }}
                            className={({ isActive }) => isActive ? 'flex p-2 items-center text-left relative w-full justify-between bg-[#036AD1] text-[#fff] rounded-md' : 'bg-[#F9FCFF] text-left flex justify-between font-normal p-2 items-center relative w-full text-[#3B3B3B]'}
                        >
                            <div className='items-center flex'>
                                <Iconbadge props={data} />
                                <p className='px-5 text-[18px] max-md:text-[16px] max-sm:text-[14px] text-left'>{data.linkTitle}</p>
                            </div>
                            <Arrow props={data} />
                        </NavLink>
                    </div>
                ))}
            </div>
            <div className='flex my-2 px-8 items-center' onClick={signout}>
                <img className='w-5 h-5 max-md:w-4 max-md:h-4 max-sm:w-3 max-sm:h-3 mt-1' src={Logouticon} alt='icon' />
                <p className='text-[20px] max-md:text-[16px] max-sm:text-[14px] pl-5 text-[#FF0000]'>Logout</p>
            </div>
            <div className='flex px-8 p-2'>
                <img src={haveQureies} alt='icon' />
                <p className='text-[24px] max-md:text-[20px] max-sm:text-[16px] font-bold text-[#000000] px-5 max-md:px-3'>Have Queries</p>
            </div>
            <div className='flex mb-5 bg-[#F6F6F6] w-fit py-2 ml-20 px-4 rounded-lg'>
                <img src={callusblack} alt='icon' />
                <p className='pl-3 text-[#000]'>Call Us</p>
            </div>
        </div>
    );
}

export default LeftNav;
