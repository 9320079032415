import { useState } from "react";
import { useLocation } from "react-router-dom";
import Header from "../elements/header";
import ReactPlayer from "react-player/youtube";
import Footer from "../elements/Footer";

function ResourceDetail() {
  const { state } = useLocation();
  const [resourcedetail] = useState(state);
  let htmlString = resourcedetail.desc;

  // Convert the HTML string to a DOM node
  let parser = new DOMParser();
  let doc = parser.parseFromString(htmlString, "text/html");

  // Function to wrap content with scrollable div
  function wrapWithScroll(element: any) {
    let wrapper = document.createElement("pre");
    wrapper.className = "overflow-auto h-60 bg-gray-100 p-2 rounded shadow";
    wrapper.appendChild(element.cloneNode(true)); // Keep the original in the DOM
    return wrapper;
  }

  // Find all <pre><code> blocks and wrap them
  let codeBlocks = doc.querySelectorAll("pre code");
  codeBlocks.forEach((block: any) => {
    let wrapper = wrapWithScroll(block);
    block.parentNode.replaceChild(wrapper, block);
  });

  // Serialize the modified DOM back to a string
  let serializer = new XMLSerializer();
  let finalText = serializer.serializeToString(doc.documentElement);

  return (
    <div>
      <Header />
      <div className="w-11/12 max-mobile:h-[73vh] mx-auto bg-[#fFF] ">
        <p className="p-3 text-[#000000] text-[34px] font-medium max-mobile:text-[18px]">
          {resourcedetail.title}
        </p>
        {resourcedetail.content_type === "External Link" ? (
          <div className="bg-[#ffffff] mb-10">
            <ReactPlayer
              className="w-full h-[50vh] mx-auto"
              url={resourcedetail?.data?.external_link}
            />
          </div>
        ) : resourcedetail.content_type === "Upload Video" ? (
          <div className="bg-[#ffffff]   mb-10">
            <iframe
              className="w-full h-[85vh]  "
              src={resourcedetail?.data?.video?.url}
            ></iframe>
          </div>
        ) : resourcedetail.content_type === "Text" ? (
          <div className="bg-[#ffffff] break-words container mb-10">
            {resourcedetail.desc ? (
              <div
                className=" p-4 rounded-lg overflow-x-auto  text-balance max-mobile:text-[12px] whitespace-pre-wrap break-normal"
                style={{ textAlign: "left" }}
                dangerouslySetInnerHTML={{ __html: finalText }}
              ></div>
            ) : (
              <div></div>
            )}
          </div>
        ) : (
          <div className="bg-[#fff] max-mobile: w-[50vw] mx-auto mt-4  mb-10">
            {resourcedetail.desc ? (
              <img
                className="w-5/6 mx-auto  aspect-5/3"
                src={resourcedetail.image}
                alt="titleImg"
              />
            ) : (
              <img
                className="w-full h-full"
                src={resourcedetail.image}
                alt="titleImg"
              />
            )}
            {resourcedetail.desc ? (
              <div dangerouslySetInnerHTML={{ __html: finalText }}></div>
            ) : (
              <div></div>
            )}
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default ResourceDetail;
