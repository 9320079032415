import React, { useEffect, useState, CSSProperties } from "react";
import CourseSessionCard from "../elements/courseSessionCard";
import Header from "../elements/header";
import { SessionCard } from "../elements/courseSessionCard";
import Coursedata from "../../content/coursedata.json";
import CourseStartPage from "./courseStartPage";
import {
  getCourses,
  getFreeCourseProgress,
  getPaidCourseProgress,
  getSessionsByStudentUid,
} from "../../function/Student/CourseProgree/CourseProgress";
// import { uuid } from '../../redux/useReducer'
import CourseCard from "../elements/courseCard";
import { getLatestTraining } from "../../function/Student/job/jobs";
import { Progressbar } from "../elements/progressBar";
import ClipLoader from "react-spinners/ClipLoader";
import { useLocation, useNavigate } from "react-router-dom";
import { isArray } from "lodash";
import Footer from "../elements/Footer";

function CoursePage() {
  const uuid: any = localStorage.getItem("uid");
  const [courseData, setCourseData] = useState({}) as any;
  const [courseid, setcourseid] = useState("") as any;
  const [progressState, setProgressState] = useState(false);
  const [typestate, setTypestate] = useState(false);
  const [paidData, setPaidData] = useState({}) as any;
  const [freeData, setFreeData] = useState({}) as any;
  const navigate = useNavigate();
  const [freeDatatitle, setFreeDatatitle] = useState({}) as any;
  const [pagestate, setPagestate] = useState(false);
  let [loading, setLoading] = useState(true);

  const override: CSSProperties = {
    flex: 1,
    marginTop: 240,
    justifyContent: "center",
    alignItems: "center",
  };
  const data = async () => {
    setLoading(true);
    const data: any = await getCourses(uuid);
    setCourseData(data);
    // const trainingId: any = await getLatestTraining(uuid)
    setPagestate(true);
    setProgressState(true);
    setLoading(false);
  };

  const [count, setCount] = useState(9);
  const handleLoad = () => {
    let data_Lenght = paidData?.session_data?.length
      ? paidData?.session_data?.map((item: any) => {
          return item;
        })
      : 0;
    setCount(
      paidData?.session_data == undefined ? 0 : paidData?.session_data?.length
    );
  };

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
    });
    data();
  }, []);
  let element_div = null;
  const handlePaid = async (props: any) => {
    const data: any = await getPaidCourseProgress(props?.id, uuid);
    // console.log(data, "data_QWE")

    setPaidData(data);
    setProgressState(false);
    setTypestate(true);
  };

  const handleFree = async (props: any) => {
    const data = await getFreeCourseProgress(props?.id);
    setcourseid(props?.id);
    setFreeData(data);
    setFreeDatatitle(props);
    setProgressState(false);
  };
  const handleClick = (props: any) => {
    navigate("/single-videos", {
      state: { allCourse: courseid, selectCourse: props },
    });
  };
  return (
    <>
      <Header />
      {pagestate ? (
        <div>
          {progressState ? (
            <div>
              <p className="pt-5 text-[25px] text-left  text-[#707070]   px-10">
                My Courses
              </p>
              <hr className=" mx-10 " />
              {isArray(courseData) && courseData?.length ? (
                <div className="grid max-monitor:grid-cols-3 mt-5 gap-4 px-10 max-mobile:px-5 max-desktop:grid-cols-3 max-laptop:grid-cols-2 max-mobile:grid-cols-1 py-10">
                  {courseData?.map((item: any) => {
                    item?.course?.type === "Paid"
                      ? (element_div = (
                          <div onClick={() => handlePaid(item)}>
                            <div className="w-[350px] max-mobile:w-[79vw] mx-auto cursor-pointer">
                              <CourseCard
                                image={item.course.thumbnail.url}
                                value={item.course.course_name}
                                type={item.course.type}
                              />
                            </div>
                          </div>
                        ))
                      : (element_div = "");
                    return element_div;
                  })}
                </div>
              ) : (
                <p className="text-center flex items-center justify-center mt-32 max-mobile:mt-20 text-[22px] max-tablet:text-[22px] max-mobile:text-[16px] w-[70%] max-tablet:w-[70%] max-mobile:w-[80%] mx-auto">
                  Currently, no courses are available for you. <br></br>
                  To get started, please explore our course catalog and enroll
                  in a course now.
                </p>
              )}
              <div className="grid max-monitor:grid-cols-3 mt-5 gap-4 px-10 max-mobile:px-5 max-desktop:grid-cols-3 max-laptop:grid-cols-2 max-mobile:grid-cols-1 py-10">
                {isArray(courseData) && courseData.length
                  ? courseData?.map((item: any) => {
                      item?.course?.type === "Free"
                        ? (element_div = (
                            <div onClick={() => handleFree(item)}>
                              <div className="w-[350px]  max-mobile:w-[79vw] mx-auto cursor-pointer">
                                <CourseCard
                                  image={item.course.thumbnail.url}
                                  value={item.course.course_name}
                                  type={item.course.type}
                                />
                              </div>
                            </div>
                          ))
                        : (element_div = "");
                      return element_div;
                    })
                  : ""}
              </div>
            </div>
          ) : (
            <div>
              {typestate ? (
                <div>
                  {paidData?.status === "no_classes" ? (
                    <CourseStartPage flag={paidData} />
                  ) : paidData?.status === "not_allocated" ? (
                    <CourseStartPage flag={paidData} />
                  ) : (
                    <div className="block items-center min-h-[84vh]  bg-[#F9FCFF] max-tablet:bg-[#F9FCFF] max-mobile:bg-[#fff] pt-[3%] ">
                      <CourseSessionCard
                        title="Course Progress"
                        courseName={paidData?.alteredData?.course_name}
                        trainer={paidData?.alteredData?.trainer_name}
                        branch={paidData?.alteredData?.branch}
                        city={paidData?.alteredData?.city}
                        percentage={
                          paidData?.alteredData?.course_progress
                            ? paidData?.alteredData?.course_progress
                            : 0
                        }
                      />
                      <div className="grid gap-2 p-10 max-laptop:grid-cols-2 max-monitor:grid-cols-3 max-desktop:grid-cols-3 max-mobile:grid-cols-1 mx-auto">
                        {paidData?.session_data
                          ?.slice(0, count)
                          ?.map((item: any, index: any) => {
                            return (
                              <SessionCard
                                attendents={
                                  item.is_present ? "Present" : "Absent"
                                }
                                sessionMessage={
                                  index == 0 &&
                                  item.is_present &&
                                  item.recent_session &&
                                  item.feedbackGiven == false
                                    ? "Give Feedback"
                                    : "View Session"
                                }
                                date={item.session_date}
                                subtitle={item.title}
                                data={paidData}
                                sessionData={item}
                              />
                            );
                          })}
                      </div>
                      {paidData?.session_data?.length > count ? (
                        <button
                          onClick={handleLoad}
                          className="text-white text-center mt-5  w-44 bg-gradient-to-r from-[#E91F00] via-[#E93C00] to-[#E95900] font-normal rounded-md text-[20px] px-5 py-2.5 mb-12"
                        >
                          {" "}
                          Load More{" "}
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                  )}
                </div>
              ) : (
                <div className="my-5">
                  <div className="flex items-center mx-auto text-left justify-between w-4/6 p-6 bg-white border border-gray-200 rounded-lg shadow max-laptop:w-5/6  ">
                    <div className=" justify-items-center  align-middle ">
                      <div className="text-[30px] max-mobile:text-[22px] max-tablet:text-[30px] font-bold text-[#707070]">
                        Course Progress{" "}
                      </div>
                      <div>
                        <p>
                          <span className=" font-bold"> Course:</span>{" "}
                          {freeDatatitle?.course?.course_name}
                        </p>
                        <p>
                          <span className=" font-bold"> Duration:</span>{" "}
                          {freeData?.duration}
                        </p>
                      </div>
                    </div>
                    <div className="text-right justify-items-end align-middle max-mobile:justify-items-center ">
                      <Progressbar
                        percentage={freeData?.overAllProgress}
                        className="w-[100px] h-[100px] max-mobile:w-[70px] max-mobile:h-[70px]"
                      />
                    </div>
                  </div>

                  <div className="grid grid-cols-2 mt-5 gap-4 px-10 max-tablet:px-10 max-mobile:px-2  max-mobile:grid-cols-1 py-10 ">
                    {freeData?.course?.videos?.map((item: any) => {
                      return (
                        <div
                          onClick={() => handleClick(item)}
                          className="mx-auto flex justify-between p-5 items-center align-middle w-[70%] shadow-3xl rounded-lg cursor-pointer max-mobile:w-[80vw]"
                        >
                          <div className=" text-left items-center">
                            <p className=" text-[#036AD1] text-[20px] font-bold">
                              {item.title}
                            </p>
                            <p className=" text-[#707070] text-[20px] pt-3">
                              {item.duration} &nbsp; HRS
                            </p>
                          </div>
                          <div>
                            <Progressbar
                              percentage={item.progress ? item.progress : 0}
                              className="w-[80px] h-[80px]"
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      ) : (
        <div>
          <ClipLoader
            color="#036AD1"
            loading={loading}
            cssOverride={override}
          />
        </div>
      )}
      <Footer />
    </>
  );
}

export default CoursePage;
