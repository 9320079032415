import { useEffect, useState, CSSProperties } from "react";
import {
  getAllFeedbacks,
  getCourses,
  getProfileDetails,
  getStudentConcerns,
  submitConcernOrFeedback,
} from "../../function/Student/Profile/Profile";
import closeicon from "../../images/close-red-icon.svg";
import { updateStatus } from "../../function/Student/Feedback/Feedback";
import { dateFormat } from "../elements/dateConvertion";
import eyeIcon from "../../images/eyeIcon.svg";
import ClipLoader from "react-spinners/ClipLoader";

function Feedback() {
  const uuid: any = localStorage.getItem("uid");
  const [onSubmit, setOnsubmit] = useState(false);
  const [courseInfo, setCourseInfo] = useState({}) as any;
  const [selectedCourse, setSelectedCourse] = useState({}) as any;
  const [text, setText] = useState("");
  const [courseSelectState, setCourseselectState] = useState(false);
  const [textState, setTextstate] = useState(false);
  const [submitState, setSubmitstate] = useState(false);
  const [pageState, setPagestate] = useState(false);
  const [viewPage, setViewpage] = useState(true);
  const [feedbackRes, setFeedbackres] = useState({}) as any;
  const [sessionFeed, setSessionfeed] = useState({}) as any;
  const [personData, setPersonData] = useState({}) as any;
  const [firstLetter, setFirstLetter] = useState("");
  const [tableName, setTableName] = useState("feedbacks");
  const [comment, setComment] = useState("");
  const [reply, setReply] = useState("");
  const [reOpen, setReOpen] = useState("");
  const [feedbackId, setFeedbackId] = useState("");
  const [divHidden, setDivhidden] = useState(false);
  const [load, setLoad] = useState(false);
  let [loading, setLoading] = useState(true);
  const override: CSSProperties = {
    flex: 1,
    marginTop: 240,
    justifyContent: "center",
    alignItems: "center",
  };
  var length = 50;
  
  const handleSubmit = async () => {
    let trainingId = selectedCourse;
    let concern = text;

    if (textState === true && courseSelectState === true) {
      await submitConcernOrFeedback({
        concern_or_feedback: concern,
        training_id: trainingId,
        student_id: uuid,
      });
      setOnsubmit(false);
      setSubmitstate(true);
    }
    setSubmitstate(false);
    setDivhidden(true);
  };
  const datas = async () => {
    setLoading(true);
    let coursedata = await getCourses(uuid);
    setCourseInfo(coursedata);
    const data = await getAllFeedbacks(uuid);
    setFeedbackres(data);
    const data_ses = await getStudentConcerns(uuid);
    setSessionfeed(data_ses);
    const personalData = await getProfileDetails(uuid);
    setPersonData(personalData);
    title(personalData);
    setLoad(true);
    setPagestate(true);
    setOnsubmit(true);
    setLoading(false);
  };
  const handleChange = (e: any) => {
    courseInfo.map((item: any) => {
      if (item?.course?.course_name === e.value) {
        setSelectedCourse(item?.id);
      }
    });
    setCourseselectState(true);
  };
  const handleText = (e: any) => {
    setText(e.value);
    setTextstate(true);
  };

  const handleClose = () => {
    window.location.reload();
    setDivhidden(false);
    setOnsubmit(true);
  };

  const handleClose1 = () => {
    setPagestate(true);
  };

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
    });
    datas();
  }, []);

  const handleFeed = () => {
    setViewpage(true);
    setTableName("feedbacks");
  };
  const handleFeed1 = () => {
    setViewpage(false);
    setTableName("student_concern_feedback");
  };
  const title = async (props: any) => {
    let title_str = "";
    await props?.name?.split(" ").forEach((element: any) => {
      title_str += element[0].toUpperCase();
    });
    setFirstLetter(title_str);
  };

  const replyValue = (props: any) => {
    setReOpen(props.status);
    setFeedbackId(props.id);
    setComment(props.comment ? props?.comment : props?.concern_or_feedback);
    setReply(props?.reply);
    setPagestate(false);
  };

  const FeedBackReopen = async () => {
    await updateStatus(tableName, feedbackId);
    datas();
  };

  return (
    <div className="bg-[#FFFFFF] min-h-screen">
      {!loading ? (
        <>
          {" "}
          {load ? (
            <div>
              {pageState ? (
                <div>
                  <div className="p-6 text-left mx-auto">
                    <p className="text-[25px] max-mobile:text-[20px] text-[#707070]">
                      Concern / Feedback{" "}
                    </p>
                    <hr className="mt-4" />
                  </div>
                  <div hidden={divHidden}>
                    <div className=" flex justify-end m-5 max-mobile:m-1 max-mobile:w-[100vw] max-tablet:justify-end max-mobile:justify-center">
                      <div
                        className={`my-4 text-white ${
                          tableName == "feedbacks"
                            ? "bg-[#036AD1] "
                            : "bg-sky-500 "
                        }  cursor-pointer   font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2`}
                        onClick={handleFeed}
                      >
                        Session Feedback
                      </div>
                      <div
                        className={`my-4 text-white ${
                          tableName == "student_concern_feedback"
                            ? "bg-[#036AD1] "
                            : "bg-sky-500 "
                        }  cursor-pointer   font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2`}
                        onClick={handleFeed1}
                      >
                        Course Feedback
                      </div>
                    </div>
                    {viewPage ? (
                      <div className="px-10 max-tablet:px-10 max-mobile:px-2">
                        {tableName == "feedbacks" ? (
                          <p className="text-start text-[24px] max-tablet:text-[18px] font-bold mb-2 text-[#707070]">
                            Session Feedback
                          </p>
                        ) : (
                          ""
                        )}
                        <div className="flex bg-[#F9FCFF] max-mobile:text-[14px] w-[100%] max-mobile:w-[96vw] py-3 shadow-3xl">
                          <p className=" w-[6%]">#</p>
                          <p className=" w-[15%]">Created Date</p>
                          <p className=" w-[20%]">Course</p>
                          <p className=" w-[15%]">Status</p>
                          <p className=" w-[30%]">Comments</p>
                          <p className=" w-[14%]">Action</p>
                        </div>
                        <div className=" h-[200px] overflow-y-scroll  shadow-3xl">
                          {feedbackRes?.length ? (
                            feedbackRes?.map((item: any, index: number) => {
                              return (
                                <div className="flex bg-[#fff] max-mobile:text-[14px] w-[100%] max-mobile:w-[95vw] py-3 ">
                                  <p className=" w-[6%]">{index + 1}</p>
                                  <p className=" w-[15%] break-words">
                                    {dateFormat(
                                      item?.created_at,
                                      " DD MMM YYYY"
                                    )}
                                  </p>
                                  <p className=" w-[20%] break-words">
                                    {item?.training?.course?.course_name}
                                  </p>
                                  <p className=" w-[15%]">{item?.status}</p>
                                  <p className=" w-[30%] break-all">
                                    {item?.comment?.substring(0, length)}{" "}
                                  </p>
                                  <p className="w-[14%] flex justify-center">
                                    <img
                                      className=" w-[20%] max-mobile:w-[40%] cursor-pointer"
                                      src={eyeIcon}
                                      alt="eye cion"
                                      onClick={() => replyValue(item)}
                                    />
                                  </p>
                                </div>
                              );
                            })
                          ) : (
                            <div className="mt-20">No Data</div>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div className="px-10 max-tablet:px-10 max-mobile:px-2">
                        {tableName != "feedbacks" ? (
                          <p className="text-start text-[24px] max-tablet:text-[18px] font-bold mb-2 text-[#707070]">
                            Course Feedback
                          </p>
                        ) : (
                          ""
                        )}

                        <div className="flex bg-[#F9FCFF] max-mobile:text-[14px] w-[100%] max-mobile:w-[95vw] py-3 shadow-3xl">
                          <p className=" w-[6%]">#</p>
                          <p className=" w-[15%]">Created Date</p>
                          <p className=" w-[20%]">Course</p>
                          <p className=" w-[15%]">Status</p>
                          <p className=" w-[30%]">comments</p>
                          <p className=" w-[14%]">Action</p>
                        </div>
                        <div className=" h-[200px] max-mobile:w-[95vw] overflow-y-scroll  shadow-3xl">
                          {sessionFeed.length ? (
                            sessionFeed?.map((item: any, index: number) => {
                              return (
                                <div className="flex max-mobile:text-[14px] bg-[#fff] w-[100%] py-3 ">
                                  <p className=" w-[6%]">{index + 1}</p>
                                  <p className=" w-[15%] break-words">
                                    {dateFormat(
                                      item?.created_at,
                                      " DD MMM YYYY"
                                    )}
                                  </p>
                                  <p className=" w-[20%] break-words">
                                    {item?.training?.course?.course_name}
                                  </p>
                                  <p className=" w-[15%]">{item?.status}</p>
                                  <p className=" w-[30%] break-words">
                                    {item?.concern_or_feedback
                                      ? item?.concern_or_feedback.length > 10
                                        ? item?.concern_or_feedback.substring(
                                            0,
                                            length
                                          ) + ".."
                                        : item?.concern_or_feedback.substring(
                                            0,
                                            length
                                          ) + ""
                                      : ""}
                                  </p>
                                  <p className="w-[14%] flex justify-center">
                                    <img
                                      className="w-[30%] max-mobile:w-[50%]   cursor-pointer"
                                      src={eyeIcon}
                                      alt="eye cion"
                                      onClick={() => replyValue(item)}
                                    />
                                  </p>
                                </div>
                              );
                            })
                          ) : (
                            <div className="mt-20">No Data</div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>

                  <div>
                    {onSubmit ? (
                      <div className="max-w-2xl w-full mx-auto my-14 p-6 shadow-lg rounded-2xl bg-white">
                        <h2 className="text-2xl font-semibold text-gray-700 mb-6 text-center">
                          Concern / Feedback
                        </h2>

                        {/* Course Select Dropdown */}
                        <label
                          htmlFor="course"
                          className="block text-sm font-medium text-gray-600 mb-2"
                        >
                          Select Course
                        </label>
                        <select
                          id="course"
                          className="w-full border-2 rounded-lg px-4 py-2 outline-none cursor-pointer focus:ring-2 focus:ring-blue-500"
                          onChange={(e) => handleChange(e.target.value)}
                          //   value={selectedCourse}
                        >
                          <option value="" disabled>
                            Select Course
                          </option>
                          {courseInfo?.map((item: any) => (
                            <option
                              key={item?.course?.id}
                              value={item?.course?.course_name}
                            >
                              {item?.course?.course_name}
                            </option>
                          ))}
                        </select>

                        {/* Feedback Textarea */}
                        <label
                          htmlFor="feedback"
                          className="block text-sm font-medium text-gray-600 mt-4 mb-2"
                        >
                          Write a Concern / Feedback
                        </label>
                        <textarea
                          id="feedback"
                          className="w-full border-2 rounded-lg px-4 py-2 h-24 outline-none focus:ring-2 focus:ring-blue-500"
                          placeholder="Write a Concern / Feedback"
                          onChange={(e) => handleText(e.target.value)}
                          //   value={feedback}
                        />

                        {/* Submit Button */}
                        <button
                          type="button"
                          className={`w-full mt-6 bg-blue-600 text-white font-medium py-2 rounded-lg
                                  transition-all duration-200 ${
                                    submitState
                                      ? "opacity-50 cursor-not-allowed"
                                      : "hover:bg-blue-700"
                                  }`}
                          onClick={handleSubmit}
                          disabled={submitState}
                        >
                          {submitState ? "Submitting..." : "Submit"}
                        </button>
                      </div>
                    ) : (
                      <div className="w-[40%] max-tablet:w-[40%] max-mobile:w-[80vw]  mx-auto shadow-3xl my-32 max-monitor:my-44 p-10 relative  max-desktop:w-[40%]">
                        <img
                          className="cursor-pointer  right-5  top-3 absolute mb-5 w-6 h-6 "
                          onClick={handleClose}
                          src={closeicon}
                          alt="closeicon"
                        />
                        <p>
                          Your Concern is sent to the respective Manager. Be
                          assured that our team will get in touch with you with
                          in 1 working day
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                // <div className=" min-h-screen  pt-[20%]">
                //   <div className="w-[60%] max-mobile:w-[80vw] max-tablet:w-[60%] pb-10 shadow-3xl mx-auto relative items-center align-middle my-5">
                //     <img
                //       className="cursor-pointer  right-5  top-3 absolute mb-5 w-6 h-6 "
                //       onClick={handleClose1}
                //       src={closeicon}
                //       alt="closeicon"
                //     />
                //     <div className="flex  items-center p-5">
                //       <p className=" bg-[#036AD1] text-[#fff] rounded-full p-1.5 w-[35px] h-[35px]">
                //         {firstLetter}
                //       </p>
                //       <p className="pl-5">{personData.name}</p>
                //     </div>
                //     <p className=" text-left break-all text-[18px] pl-14 pr-6">
                //       {comment}
                //     </p>
                //     <div className="flex">
                //         <label>Reply: </label>
                //     <p className=" text-left text-[20px] max-mobile:text-[16px] max-mobile:pl-3 pl-32 pb-3">
                //       {reply
                //         ? reply
                //         : "Our Team working on your concern, we will get bact to you shortly!"}
                //     </p>
                //     </div>
                //     {reOpen == "Resolved" ? (
                //       <button
                //         className="border-2 px-4 py-2 w-[100px] ml-20 mt-3 mb-5"
                //         onClick={() => FeedBackReopen()}
                //       >
                //         Reopen
                //       </button>
                //     ) : (
                //       ""
                //     )}
                //   </div>
                // </div>
                <div className="min-h-screen pt-[20%] flex justify-center items-start">
                  <div className="bg-white w-full max-w-[500px] shadow-lg rounded-2xl p-6 relative">
                    <button
                      className="absolute top-4 right-4 w-8 h-8 bg-red-500 text-white flex items-center justify-center rounded-full cursor-pointer"
                      onClick={handleClose}
                    >
                      ✕
                    </button>

                    <div className="flex items-center gap-4">
                      <div className="bg-[#036AD1] text-white rounded-full w-[40px] h-[40px] flex items-center justify-center text-lg font-bold">
                        {firstLetter}
                      </div>
                      <p className="text-lg font-semibold">{personData.name}</p>
                    </div>

                    <div className="mt-4">
                      <p className="text-sm font-medium text-gray-500">
                        Comments:
                      </p>
                      <p className="text-base text-gray-700 break-words">
                        {comment}
                      </p>
                    </div>

                    <div className="mt-4">
                      <p className="text-sm font-medium text-gray-500">
                        {reply ? "Reply:" : "Auto Reply:"}
                      </p>
                      <p className="text-base text-gray-700">
                        {reply ||
                          "Our team is working on your concern, we will get back to you shortly!"}
                      </p>
                    </div>

                    {reOpen === "Resolved" && (
                      <button
                        className="mt-4 bg-blue-500 text-white px-4 py-2 rounded-md w-full"
                        onClick={FeedBackReopen}
                      >
                        Reopen
                      </button>
                    )}
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div></div>
          )}
        </>
      ) : (
        <>
          {" "}
          <ClipLoader
            color="#036AD1"
            loading={loading}
            cssOverride={override}
          />
        </>
      )}
    </div>
  );
}

export default Feedback;
