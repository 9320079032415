import React, { useState, useEffect, CSSProperties } from "react";
import CourseCard from "../elements/courseCard";
import profileCourseData from "../../content/profileCourseData.json";
import shareicon from "../../images/shareIcon.svg";
import downloadIcon from "../../images/downloadicon.svg";
import certificateimg from "../../images/certification.png";
// import { uuid } from '../../redux/useReducer'
import { getCertificate } from "../../function/Student/Certificate/Certificate";
import { RWebShare } from "react-web-share";
import {
  getAllCertificates,
  getCourses,
  getProfileDetails,
} from "../../function/Student/Profile/Profile";
import Fitacertifcate from "./fitacertifcate";
import jsPDF from "jspdf";
import fitacertificate from "../../images/fitacertificate1.png";
import { renderToString } from "react-dom/server";
import ClipLoader from "react-spinners/ClipLoader";

function Mycertificate() {
  const uuid: any = localStorage.getItem("uid");
  const [btnstatus, setBtnststus] = useState(true);
  const [certificateUrl, setCertificateurl] = useState({}) as any;
  // const [courseData, setCourseData] = useState({}) as any
  const [courseState, setCourseState] = useState(false);
  const [userDetails, setDetails] = useState({}) as any;
  const [courseName, setCourseName] = useState({}) as any;
  const [onClickCourse, setOnclickcourse] = useState({}) as any;
  let [loading, setLoading] = useState(true);
  const override: CSSProperties = {
    flex: 1,
    marginTop: 240,
    justifyContent: "center",
    alignItems: "center",
  };
  const handleClick = async (props: any) => {
    setDetails(props);
    setBtnststus(false);
  };

  const print = async () => {
    let content = renderToString(
      <Fitacertifcate
        name={userDetails?.student?.name}
        course={userDetails?.course?.course_name}
        branch={userDetails?.branches?.branch_name}
        city={userDetails?.city?.city_name}
        duration={userDetails?.course?.duration}
        completion={userDetails?.date_of_completion}
        sNo="112344"
        className="!mt-[6px]"
      />
    );
    const doc = new jsPDF({
      format: "a4",
      unit: "pt",
    });
    doc.addImage(fitacertificate, "PNG", 0, 0, 595, 842);
    doc.html(content ? content : "", {
      async callback(doc) {
        await doc.save("pdf_name");
      },
    });
    // doc.html(content);
    // doc.save("a4.pdf");
  };

  const data = async () => {
    setLoading(true);
    const certificatesdata = await getCertificate();

    setCertificateurl(certificatesdata);
    // const CourseData = await getCourses(uuid)
    // setCourseData(CourseData)

    const certiData: any = await getAllCertificates(uuid);
    let certificates = certiData?.filter(
      (certificate: any) => certificate.date_of_completion
    );
    setOnclickcourse(certificates);
    setCourseState(true);
    setLoading(false);
  };

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
    });
    data();
  }, []);
  let element_div = null;

  return (
    <div className=" min-h-screen max-mobile:pb-1 bg-[#FFFFFF]">
      {!loading ? (
        <>
          <div className="p-10 text-left mx-auto max-mobile:p-2">
            <p className="text-[25px] max-mobile:text-[22px]  text-[#707070] ">
              My Certificate{" "}
            </p>
            <hr className="my-4" />
            {courseState ? (
              <div>
                {btnstatus ? (
                  <div className="">
                    <div className="grid max-monitor grid-cols-3  gap-0 px-10 max-mobile:-ml-5  max-desktop:grid-cols-3 max-laptop:grid-cols-2 max-mobile:grid-cols-1 py-4">
                      {onClickCourse.length
                        ? onClickCourse?.map((item: any) => {
                            item?.course?.type === "Paid"
                              ? item.date_of_completion
                                ? (element_div = (
                                    <div>
                                      <div className="m-4">
                                        <CourseCard
                                          image={item.course.thumbnail.url}
                                          value={item.course.course_name}
                                          type={item.course.type}
                                        />
                                        {item.date_of_completion ? (
                                          <p
                                            onClick={() => handleClick(item)}
                                            className="text-[26px]  max-mobile:w-[40vw] w-[70%] max-desktop:text-[18px] max-laptop:w-[90%] max-tablet:text-[18px] max-mobile:text-[15px]  mx-auto cursor-pointer text-[#ffffff] bg-gradient-to-r from-[#E91F00] via-[#E93C00]
                       to-[#E95900]  p-2 text-center mt-5 rounded-md"
                                          >
                                            View Certificate
                                          </p>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                  ))
                                : (element_div = "")
                              : (element_div = "");
                            return element_div;
                          })
                        : ""}
                    </div>

                    {onClickCourse.length == 0 && (
                      <p className="text-center pt-5 xl:w-[100%] 2xl:w-[60%] mx-auto xl: text-[20px] text-[#707070] max-tablet:text-[20px] max-mobile:text-[18px] max-tablet:w-[80%]]">
                        You must need to complete 100% of your course to request
                        a certificate
                      </p>
                    )}

                    <div className="grid max-monitor grid-cols-3  max-mobile:-ml-3 gap-0 px-10  max-desktop:grid-cols-3 max-laptop:grid-cols-2 max-mobile:grid-cols-1 py-4">
                      {onClickCourse.length
                        ? onClickCourse?.map((item: any) => {
                            item?.course?.type === "Free"
                              ? item.date_of_completion
                                ? (element_div = (
                                    <div>
                                      <div className="m-2">
                                        <CourseCard
                                          image={item.course.thumbnail.url}
                                          value={item.course.course_name}
                                          type={item.course.type}
                                        />
                                        <p
                                          onClick={() => handleClick(item)}
                                          className="text-[26px] w-[70%] max-tablet:w-[70%] max-mobile:w-[40vw] max-desktop:text-[18px] max-laptop:w-[90%] max-tablet:text-[18px] max-mobile:text-[15px]  mx-auto cursor-pointer text-[#ffffff] bg-gradient-to-r from-[#E91F00] via-[#E93C00] to-[#E95900]  p-2 text-center mt-5 rounded-md"
                                        >
                                          View Certificate
                                        </p>
                                      </div>
                                    </div>
                                  ))
                                : (element_div = "")
                              : (element_div = "");
                            return element_div;
                          })
                        : ""}
                    </div>
                  </div>
                ) : (
                  <div className=" w-[600px] mx-auto max-tablet:w-full max-mobile:w-full">
                    <div className=" flex justify-end  my-5 mx-auto ">
                      <div className=" cursor-pointer mx-5">
                        {/* <RWebShare
                data={{
                  text: "Your Certificate",
                  title: "Test",
                }}
                onClick={print1}
              >
              <div className='grid cursor-pointer'>
                <img className='mx-auto' src={shareicon} alt='icon' />
                <p className='pt-3'>Share</p>
              </div>
              </RWebShare> */}
                      </div>
                      <div className="grid cursor-pointer " onClick={print}>
                        <img
                          className="mx-auto"
                          src={downloadIcon}
                          alt="icon"
                        />
                        <p className="pt-3">Download</p>
                      </div>
                    </div>

                    {/*@ts-ignore*/}
                    <div className="max-mobile:overflow-auto max-mobile:w-[100vw]">
                      <Fitacertifcate
                        name={userDetails?.student?.name}
                        course={userDetails?.course?.course_name}
                        branch={userDetails?.branches?.branch_name}
                        city={userDetails?.city?.city_name}
                        duration={userDetails?.course?.duration}
                        completion={userDetails?.date_of_completion}
                        sNo="112344"
                        className=" bg-[url('../public/images/fitacertificate.png')] bg-contain bg-no-repeat"
                      />
                    </div>
                  </div>
                )}{" "}
              </div>
            ) : (
              <div> </div>
            )}
          </div>
        </>
      ) : (
        <>
          <ClipLoader
            color="#036AD1"
            loading={loading}
            cssOverride={override}
          />
        </>
      )}
    </div>
  );
}

export default Mycertificate;
